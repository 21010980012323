import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Dropdown = ({
  dropdownTitle,
  dropdownOptions,
  maxDropdownHeight,
  dropdownDisabled,
  type,
}) => {
  return (
    <Menu as='div' className='relative inline-block text-left'>
      <div>
        <Menu.Button
          disabled={dropdownDisabled}
          className={
            type === 'content'
              ? `inline-flex justify-center w-full rounded
                border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm
                font-medium text-gray-700 hover:bg-gray-50 
                focus:outline-white focus:outline-offset-2
                focus:drop-shadow-blue-bay focus:border-blue-bay 
                disabled:bg-white-smoke disabled:cursor-not-allowed
                disabled:hover:border-neon-silver disabled:text-spanish-gray`
              : 'flex flex-row items-center'
          }
        >
          {dropdownTitle}
          <ChevronDownIcon className='-mr-1 ml-2 h-5 w-5' aria-hidden='true' />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items
          className={classNames(
            `origin-top-left absolute left-0 mt-2 rounded shadow-dialog bg-white ring-1
            ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none`,
            type === 'content' ? 'w-56' : 'w-40'
          )}
        >
          <div className={maxDropdownHeight ? 'overflow-y-auto max-h-60' : ''}>
            {dropdownOptions}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

Dropdown.defaultProps = {
  dropdownDisabled: false,
};

export default Dropdown;
