import React, { useState, useContext } from 'react';
import { AccessTokenContext, UserContext } from '../../MainContent';
import { verifyFactor } from '../../../helpers/stepUpAuth';
import Button from '../../shared/Button';
import Input from '../../shared/Input';
import LoadingDialog from '../../shared/LoadingDialog';

const VerifyChallenge = ({ setChallengeState, setStepUpState, stepUpData }) => {
  const userInfo = useContext(UserContext);
  const accessToken = useContext(AccessTokenContext);
  const [otpState, setOtpState] = useState('');
  const [inputError, setInputError] = useState(false);
  const [verifyState, setVerifyState] = useState(false);

  const verifyChallenge = async () => {
    try {
      setVerifyState(true);
      setInputError(false);
      const verified = await verifyFactor(
        accessToken,
        userInfo.sub,
        otpState,
        'google-auth'
      );
      if (!verified) {
        // TODO: change this for an error object
        throw 'ERROR';
      }
      setStepUpState('NO_CHALLENGE');
    } catch (error) {
      setInputError(true);
      setVerifyState(false);
    }
  };

  const cancelChallenge = () => {
    if (Object.keys(stepUpData).length > 1) {
      setChallengeState('FACTOR_SELECTION');
    } else {
      setChallengeState('START');
    }
  };

  const handleInputChange = (event) => {
    setOtpState(event.target.value);
  };

  return (
    <div className='flex flex-col gap-8'>
      <LoadingDialog
        open={verifyState}
        bodyText={'Please wait a moment while we verify your information'}
        headerText={'Verifying Code'}
      />
      <h1 className='text-content-headline font-neusa font-bold border-b border-platinum py-8 px-16 text-eerie-black'>
        Verify to continue
      </h1>
      <div className='flex flex-col px-16'>
        <span className='text-lg text-carbon'>
          Please check your code on Google Authenticator
        </span>
      </div>
      <div className='flex flex-col px-16'>
        <span className='text-base text-carbon font-semibold'>
          Enter authentication code
        </span>
        <div className='w-52'>
          <Input
            inputType={'slim'}
            inputValue={otpState}
            inputPlaceholder={'123456'}
            onChangeFunction={handleInputChange}
            disabledCondition={false}
            error={inputError}
            errorMessage={'Invalid verification code'}
            id={'stepUpGoogleInput'}
          />
        </div>
      </div>
      <div className='flex flex-row gap-3 px-16'>
        <Button
          buttonType={'outlined'}
          buttonText={'Cancel'}
          disableCondition={false}
          onClickFunction={cancelChallenge}
        />
        <Button
          buttonType={'filled'}
          buttonText={'Verify'}
          disableCondition={otpState.length < 1 || otpState.length > 10}
          onClickFunction={() => verifyChallenge()}
          id={'stepUpGoogleButton'}
        />
      </div>
    </div>
  );
};

export default VerifyChallenge;
