import React, { useContext, useState, useEffect } from 'react';
import Button from '../shared/Button';
import { UserContext } from '../MainContent';

const Initial = ({ setState }) => {
  const userInfo = useContext(UserContext);
  const [user, setUser] = useState('User');

  useEffect(() => {
    if (userInfo !== undefined && userInfo !== null) {
      setUser(userInfo.email);
    }
  }, [userInfo]);

  return (
    <div
      data-testid='passwordInitial'
      className='my-8 mx-16 flex flex-col gap-6'
    >
      <div className='flex flex-col gap-1'>
        <span className='text-lg text-granite-gray'>Username</span>
        <span className='text-lg text-eerie-black font-semibold'>{user}</span>
      </div>
      <div className='flex flex-col gap-1'>
        <span className='text-lg text-granite-gray'>Password</span>
        <span className='text-lg text-eerie-black font-semibold'>
          ********************
        </span>
      </div>
      <div>
        <Button
          id='passwordInitialButton'
          buttonType='outlined'
          buttonText='Change Password'
          onClickFunction={() => {
            setState('CHANGE');
          }}
        ></Button>
      </div>
    </div>
  );
};

export default Initial;
