/**
 * This function returns true if the email factor has been correctly configured
 * as a second factor. Returns false otherwise.
 */
export const emailEnroll = async (userInfo, accessToken) => {
  try {
    const response = await fetch(
      `/api/multifactor/email/${userInfo.sub}/enroll`,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
      }
    );

    if (!response.ok) {
      throw response;
    }

    let responseJson = await response.json();

    return JSON.parse(responseJson);;
  } catch {
    return null;
  }
};

export const emailActivate = async (userInfo, accessToken, activationCode) => {
  try {
    const response = await fetch(
      `api/multifactor/email/${userInfo.sub}/activate`,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          code: activationCode,
        }),
      }
    );

    let responseJson = await response.json();
    responseJson = JSON.parse(responseJson);
    if (!response.ok) {
      throw responseJson;
    }
    return true;
  } catch (error) {
    if (error.error === 'ERROR: invalid code') {
      return false;
    } else {
      throw 'ERROR';
    }
  }
};

export const resetFactor = async (userInfo, accessToken) => {
  try {
    const response = await fetch(
      `/api/multifactor/email/${userInfo.sub}/reset`,
      {
        method: 'DELETE',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          optOut: true,
        }),
      }
    );

    if (!response.ok) {
      throw response;
    }
    return true;
  } catch (error) {
    console.log(error);
    throw 'ERROR';
  }
};


export const isEmailEnrolled = async (userInfo, accessToken) => {
  try {
    const response = await fetch(
      `/api/multifactor/email/${userInfo.sub}/details`,
      {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
      }
    );

    let isFactorEnrolled = await response.json();
    isFactorEnrolled = JSON.parse(isFactorEnrolled);
    if (!response.ok) {
      throw isFactorEnrolled;
    }

    return isFactorEnrolled;
  } catch {
    return { enrolled: false };
  }
};

export const optIntoEmail = async (userInfo, accessToken) => {
  try {
    const response = await fetch(
      `/api/multifactor/email/${userInfo.sub}/opt-in`,
      {
        method: 'PUT',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
      }
    );

    if (!response.ok) {
      throw 'ERROR.';
    }
    return true;
  } catch (error) {
    return false;
  }
};

export const optOutOfEmail = async (userInfo, accessToken) => {
  try {
    const response = await fetch(
      `/api/multifactor/email/${userInfo.sub}/opt-out`,
      {
        method: 'DELETE',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
      }
    );

    if (!response.ok) {
      throw 'ERROR.';
    }
    return true;
  } catch (error) {
    return false;
  }
};

export const maskEmail = (email) => {
  const split = email.split('@');
  const username = split[0];
  const domain = split[1];
  const maskedEmail =
    `${username.charAt(0)}....${username.charAt(username.length-1)}@${domain}`;
  return maskedEmail;
};