import React, { useEffect, useContext, useState } from 'react';
import FactorState from './FactorState';
import { optIntoGa, optOutOfGa } from '../../../helpers/mfaGoogleAuth';
import { UserContext, AccessTokenContext } from '../../MainContent';
import Switch from '../../shared/Switch';
import ConfirmDialog from '../../shared/ConfirmDialog';
import FactorError from '../FactorError';
import LoadingSpinner from '../../shared/LoadingSpinner';
import { Store } from 'react-notifications-component';
import NotificationBody from '../../shared/NotificationBody';

const FactorContainer = ({
  gaStatus,
  setGaStatus,
  isEnrolling,
  setIsEnrolling,
  factorsData,
  setFactorsData,
  factorsCount,
  setFactorsCount,
  loading,
  setLoading
}) => {
  const accessToken = useContext(AccessTokenContext);
  const userInfo = useContext(UserContext);
  const [openDialog, setOpenDialog] = useState(false);
  const [turnOff, setTurnOff] = useState(false);
  const [error, setError] = useState(false);
  const [loadingSpinner, setLoadingSpinner] = useState(false);

  useEffect(() => {
    async function optOut() {
      if (turnOff) {
        setLoading(true);
        setLoadingSpinner(true);
        let response = await optOutOfGa(userInfo, accessToken);
        if (response) {
          setError(false);
          setGaStatus(false);
          setOpenDialog(false);
          Store.addNotification({
            content: (
              <NotificationBody
                id='googleAuthOptOutNotification'
                text='Your changes are saved.'
              />
            ),
            container: 'top-right',
            dismiss: {
              duration: 30000,
              click: false,
            },
            animationIn: ['animate__animated', 'animate__fadeInRight'],
            animationOut: ['animate__animated', 'animate__fadeOutRight'],
            id: 'googleAuthOptOutNotification',
          });
          let newData = factorsData;
          delete newData['googleAuth'];
          setFactorsData(newData);
          const prevFactorsCount = factorsCount;
          setFactorsCount(prevFactorsCount - 1);
        } else {
          setError(true);
        }
        setLoading(false);
        setLoadingSpinner(false);
      }
    }
    optOut();
  }, [turnOff]);

  useEffect(() => {
    if (!gaStatus) {
      if (isEnrolling.includes('googleAuth')) {
        let tmp = [].concat(isEnrolling);
        tmp.pop();
        setIsEnrolling(tmp);
      }
    }
  }, [gaStatus]);

  async function handleInputChange(newValue) {
    if (newValue) {
      setLoading(true);
      setLoadingSpinner(true);
      let response = await optIntoGa(userInfo, accessToken);
      if (response) {
        setGaStatus(newValue);
        const prevFactorsCount = factorsCount;
        setFactorsCount(prevFactorsCount + 1);
      } else {
        setError(true);
      }
      setLoading(false);
      setLoadingSpinner(false);
    } else {
      if ('googleAuth' in factorsData) {
        setOpenDialog(true);
      } else {
        setTurnOff(true);
      }
    }
  }

  const switchHiddenText = (
    <span
      className='max-w-0 max-h-0 p-0 m-0 overflow-hidden whitespace-nowrap absolute'
    >
      Switch to toggle your Google Authenticator second factor on and off
    </span>
  );

  return (
    <div data-testid='googleAuthContainer' className='border-b border-platinum'>
      <ConfirmDialog
      id ='googleAuthTurnOffDialog'
        open={openDialog}
        setOpen={setOpenDialog}
        setResponse={setTurnOff}
        headerText='Turn off verification with Authenticator app?'
        bodyText="You won't have the option to use verification codes via Google Authenticator app when you sign in to your F5 account. This Authenticator app option will not be removed from your account and can be turned back on at any time."
      ></ConfirmDialog>
      <div className='my-9 mx-16'>
        <div className='flex flex-row justify-between'>
          <div className='flex flex-col'>
            <span className='text-base font-bold text-carbon'>
              {'googleAuth' in factorsData && gaStatus ? 'Your ' : ''}Google
              Authenticator App
            </span>
            {'googleAuth' in factorsData && gaStatus ? (
              <></>
            ) : (
              <span className='text-base text-carbon'>
                Use verification codes via Google Authenticator mobile app.
              </span>
            )}
          </div>
          <div className='h-min w-min flex flex-row-reverse gap-1'>
            <Switch
              checked={gaStatus}
              onChange={handleInputChange}
              disabled={(loading || factorsCount < 2) && gaStatus}
              id={'googleAuthSwitch'}
              buttonText={switchHiddenText}
            />
            {loadingSpinner ? (
              <LoadingSpinner id='googleAuthSwitchLoading' height='h-6' width='w-6' margin={'mb-1'} />
            ) : (
              <></>
            )}
          </div>
        </div>
        {gaStatus &&
        (isEnrolling.length === 0 || isEnrolling[0] === 'googleAuth') && !error ? (
          <FactorState
            isEnrolling={isEnrolling}
            setIsEnrolling={setIsEnrolling}
            setGaStatus={setGaStatus}
            factorsData={factorsData}
            setFactorsData={setFactorsData}
            factorsCount={factorsCount}
            setFactorsCount={setFactorsCount}
          />
        ) : error ? (
          <FactorError />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default FactorContainer;
