import React, { useState, useEffect, useContext } from 'react';
import ActiveFactor from './ActiveFactor';
import Enrollment from './Enrollment';
import FactorError from '../FactorError';
import { isGaEnrolled } from '../../../helpers/mfaGoogleAuth';
import { UserContext, AccessTokenContext } from '../../MainContent';
import { Store } from 'react-notifications-component';
import NotificationBody from '../../shared/NotificationBody';

const FactorState = ({
  isEnrolling,
  setIsEnrolling,
  setGaStatus,
  factorsData,
  setFactorsData,
  factorsCount,
  setFactorsCount
}) => {
  const [enrollState, setEnrollState] = useState('INITIAL');
  const userInfo = useContext(UserContext);
  const accessToken = useContext(AccessTokenContext);

  useEffect(() => {
    async function setState() {
      if (
        'googleAuth' in factorsData &&
        factorsData.googleAuth.status === 'ACTIVE'
      ) {
        setEnrollState('ACTIVE');
      } else {
        let details = await isGaEnrolled(userInfo, accessToken);
        if (details.enrolled) {
          setEnrollState('ACTIVE');
          let newData = factorsData;
          newData.googleAuth = {
            status: 'ACTIVE',
            enrolledDate: details.enrolledDate,
          };
          Store.addNotification({
            content: (
              <NotificationBody
                id='googleAuthOptInNotification'
                text='Your changes are saved.'
              />
            ),
            container: 'top-right',
            dismiss: {
              duration: 30000,
              click: false,
            },
            animationIn: ['animate__animated', 'animate__fadeInRight'],
            animationOut: ['animate__animated', 'animate__fadeOutRight'],
            id: 'googleAuthOptInNotification',
          });
        } else {
          setEnrollState('NOT_STARTED');
        }
      }
    }
    setState();
  }, []);

  useEffect(() => {
    if (enrollState === 'NOT_STARTED') {
      if (!isEnrolling.includes('googleAuth')) {
        let tmp = ['googleAuth'].concat(isEnrolling);
        setIsEnrolling(tmp);
      }
    } else if (enrollState === 'ACTIVE') {
      if (isEnrolling.includes('googleAuth')) {
        let tmp = [].concat(isEnrolling);
        tmp.pop();
        setIsEnrolling(tmp);
      }
    }
  }, [enrollState]);

  if (enrollState === 'NOT_STARTED') {
    return (
      <Enrollment
        setEnrollState={setEnrollState}
        setFactorsData={setFactorsData}
        factorsData={factorsData}
        factorsCount={factorsCount}
        setFactorsCount={setFactorsCount}
      />
    );
  } else if (enrollState === 'ACTIVE') {
    return (
      <ActiveFactor
        setEnrollState={setEnrollState}
        setGaStatus={setGaStatus}
        setFactorsData={setFactorsData}
        factorsData={factorsData}
        factorsCount={factorsCount}
        setFactorsCount={setFactorsCount}
      />
    );
  } else if (enrollState !== 'INITIAL') {
    return <FactorError />;
  }
};
export default FactorState;
