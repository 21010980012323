import { OktaAuth } from '@okta/okta-auth-js';

export const createOktaAuth =  (domain,clientId) => {

  const oktaAuth = new OktaAuth({
    issuer: `${domain}/oauth2/default`,
    clientId: `${clientId}`,
    redirectUri: window.location.origin + '/login/callback',
    scopes: ['openid', 'profile', 'email', 'phone'],
    secure: true,
  });
  return oktaAuth;
  

};

