import React, { useState, useContext, useEffect } from 'react';
import { AccessTokenContext, UserContext } from '../../MainContent';
import { challengeFactor, verifyFactor } from '../../../helpers/stepUpAuth';
import Button from '../../shared/Button';
import ChallengeInput from './ChallengeInput';
import LoadingDialog from '../../shared/LoadingDialog';
import LoadingSpinner from '../../shared/LoadingSpinner';

const VerifyChallenge = ({ setChallengeState, setStepUpState, stepUpData }) => {
  const userInfo = useContext(UserContext);
  const accessToken = useContext(AccessTokenContext);
  const [otpState, setOtpState] = useState('');
  const [inputError, setInputError] = useState(false);
  const [verifyState, setVerifyState] = useState(false);
  const [emailSentStatus, setEmailSentStatus] = useState(false);
  const [challengeHeader, setChallengeHeader] = useState('');
  const [challengeButtonType, setChallengeButtonType] = useState('filled');
  const [challengeButtonText, setChallengeButtonText] = useState(
    'Send authentication code'
  );

  useEffect(() => {
    if (emailSentStatus) {
      setChallengeHeader(
        <p>
          Authentication code sent to <b>{stepUpData.email.maskedEmail}</b>.
        </p>
      );
    } else {
      setChallengeHeader(
        <p>
          Send authentication code to <b>{stepUpData.email.maskedEmail}</b>.
        </p>
      );
    }
  }, [emailSentStatus]);

  const sendChallenge = async () => {
    try {
      setChallengeButtonType('outlined');
      setChallengeButtonText(
        <div className='flex flex-row'>
          {'Sending authentication code'}
          <LoadingSpinner
            height={'h-4 '}
            width={'w-4 '}
            margin={'my-auto ml-1 '}
          />
        </div>
      );
      const challenged = await challengeFactor(
        accessToken,
        userInfo.sub,
        'email'
      );
      if (!challenged) {
        throw 'ERROR';
      }
      setEmailSentStatus(true);
    } catch (error) {
      console.log(error);
      setChallengeState('CHALLENGE_ERROR');
    }
  };

  const verifyChallenge = async () => {
    try {
      setVerifyState(true);
      setInputError(false);
      const verified = await verifyFactor(
        accessToken,
        userInfo.sub,
        otpState,
        'email'
      );
      if (!verified) {
        throw 'ERROR';
      }
      setStepUpState('NO_CHALLENGE');
    } catch (error) {
      setInputError(true);
      setVerifyState(false);
    }
  };

  const cancelChallenge = () => {
    if (Object.keys(stepUpData).length > 1) {
      setChallengeState('FACTOR_SELECTION');
    } else {
      setChallengeState('START');
    }
  };

  return (
    <div className='flex flex-col gap-8'>
      <LoadingDialog
        open={verifyState}
        bodyText={'Please wait a moment while we verify your information'}
        headerText={'Verifying Code'}
      />
      <h1 className='text-content-headline font-neusa font-bold border-b border-platinum py-8 px-16 text-eerie-black'>
        Verify to continue
      </h1>
      <div className='flex flex-col px-16'>
        <span className='text-lg text-carbon'>{challengeHeader}</span>
      </div>
      {emailSentStatus ? (
        <ChallengeInput setOtpState={setOtpState} inputError={inputError} />
      ) : (
        <div className='flex flex-row px-16'>
          <Button
            buttonType={challengeButtonType}
            buttonText={challengeButtonText}
            disableCondition={false}
            onClickFunction={() => sendChallenge()}
          />
        </div>
      )}
      <div className='flex flex-row gap-3 px-16'>
        <Button
          buttonType={'outlined'}
          buttonText={'Cancel'}
          disableCondition={false}
          onClickFunction={cancelChallenge}
        />
        <Button
          buttonType={'filled'}
          buttonText={'Verify'}
          disableCondition={otpState.length < 1 || otpState.length > 10}
          onClickFunction={() => verifyChallenge()}
          id={'stepUpEmailButton'}
        />
      </div>
    </div>
  );
};

export default VerifyChallenge;
