import react from 'react';

const FactorError = () => {
  return (
    <div
      id='factorError'
      data-testid='factorError'
      className='text-base text-carbon'
    >
      <h2>An Unexpected Error happened...</h2>
    </div>
  );
  //TODO change for generic screen error message
};

export default FactorError;
