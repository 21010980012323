import React, { useContext } from 'react';
import LinkGroup from './LinkGroup';
import CustomLink from '../CustomLink';
import { SettingsContext } from '../../../App';

const Footer = () => {
  const settings = useContext(SettingsContext);
  const data = JSON.parse(settings.FooterLinks);
  let groups = [];
  let policies = [];
  for (const key in data.sections) {
    groups.push(<LinkGroup key={key} name={key} links={data.sections[key]} />);
  }
  const date = new Date();
  policies.push(
    <li key='copyright' className='px-1'>
      <span>©{date.getFullYear()} F5, Inc. All rights reserved.</span>
    </li>
  );
  for (const key in data.policies) {
    policies.push(
      <li key={key} className='px-2'>
        <CustomLink text={key} link={data.policies[key]} type='Footer-Policy' />
      </li>
    );
  }
  policies.push(<li className='px-2' key='teconsent' id='teconsent'></li>);

  return (
    <div className='w-full bg-black text-white pt-6 font-proxima'>
      <div className='max-w-[1440px] w-5/6 m-auto'>
        <div className='flex flex-row w-full'>
          <div className='hidden sm:block md:w-16 lg:w-20 xl:w-28 pr-3 2xl:mr-20 xl:mr-5 md:mr-5'>
            <img
              className='w-full pt-4'
              src='https://cdn.f5.com/webcommon/logo/logo.svg'
              alt='f5-logo'
            ></img>
          </div>
          <div className='flex flex-col sm:flex-row sm:flex-wrap justify-left w-full'>
            {groups}
          </div>
        </div>
        <div className='py-5'>
          <ul className='flex flex-wrap justify-center'>
            <li className='hidden sm:block text-base font-extrabold leading-5 px-4'>
              CONNECT WITH US
            </li>
            <li className='px-4'>
              <a href='https://twitter.com/f5'>
                <img className='h-5 w-5' src='/img/twitter.svg' />
              </a>
            </li>
            <li className='px-4'>
              <a href='https://www.linkedin.com/company/f5/'>
                <img className='h-5 w-5' src='/img/linkedin.svg' />
              </a>
            </li>
            <li className='px-4'>
              <a href='https://www.facebook.com/f5incorporated'>
                <img className='h-5 w-5' src='/img/facebook.svg' />
              </a>
            </li>
            <li className='px-4'>
              <a href='https://www.instagram.com/f5.global/'>
                <img className='h-5 w-5' src='/img/instagram.svg' />
              </a>
            </li>
            <li className='px-4'>
              <a href='https://www.youtube.com/user/f5networksinc'>
                <img className='h-5 w-5' src='/img/youtube.svg' />
              </a>
            </li>
            <li className='px-4'>
              <a href='https://devcentral.f5.com/'>
                <img className='h-6 w-6' src='/img/devcentral.svg' />
              </a>
            </li>
          </ul>
        </div>
        <hr className='border-t border-[#707070]' />
        <div className='py-5 text-xs leading-3'>
          <ul className='flex flex-wrap justify-center divide-x divide'>
            {policies}
          </ul>
        </div>
        <div id='consent_blackbar'></div>
      </div>
    </div>
  );
};
export default Footer;
