import React from 'react';
import { Store } from 'react-notifications-component';
import { CheckCircleIcon, XIcon } from '@heroicons/react/solid';

const NotificationBody = ({ text, id }) => {
  return (
    <div
      data-testid={id}
      className='border-l-[#21B54D] border-l-8 rounded bg-white w-full h-full cursor-default shadow'
      role='alert'
    >
      <div className='my-4 flex flex-row w-full justify-between items-center '>
        <div className='items-center gap-4 flex flex-row '>
          <CheckCircleIcon className='text-emerald h-5 w-5 ml-3' />
          <span className='text-lg font-bold text-carbon'>{text}</span>
        </div>
        <XIcon
          className='text-granite-gray h-4 w-4 ml-3 cursor-pointer mr-3'
          name='closeNotification'
          onClick={() => {
            Store.removeNotification(id);
          }}
        />
      </div>
    </div>
  );
};

export default NotificationBody;
