import React, { useEffect, useState, useContext } from 'react';
import StepUpContainer from '../step-up/StepUpContainer';
import FactorsContainer from './FactorsContainer';
import { needsStepUp } from '../../helpers/stepUpAuth';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import {
  UserContext,
  AccessTokenContext,
  IsUserFederatedContext,
} from '../MainContent';
import LoadingSpinner from '../shared/LoadingSpinner';

const SecurityContainer = () => {
  const accessToken = useContext(AccessTokenContext);
  const userInfo = useContext(UserContext);
  const [stepUpState, setStepUpState] = useState('START');
  const [stepUpData, setStepUpData] = useState({});
  const isUserFederated = useContext(IsUserFederatedContext);

  useEffect(() => {
    const checkStepUp = async () => {
      const [stepUpNeeded, stepUpFactors] = await needsStepUp(
        accessToken,
        userInfo.sub
      );
      setStepUpData(stepUpFactors);
      if (stepUpNeeded) {
        setStepUpState('CHALLENGE');
      } else {
        setStepUpState('NO_CHALLENGE');
      }
    };
    if (accessToken !== null && userInfo !== null && stepUpState === 'START') {
      setStepUpState('LOADING');
      checkStepUp();
    }
  }, [accessToken, userInfo, isUserFederated]);

  if (isUserFederated === null) return <></>;
  if (stepUpState === 'LOADING' || stepUpState === 'START') {
    return (
      <div className='h-content w-content bg-white rounded main-content-shadow flex justify-center'>
        <div className='flex flex-col items-center m-auto space-y-2'>
          <LoadingSpinner />
          <span className='text-xl font-semibold text-eerie-black'>
            Loading
          </span>
        </div>
      </div>
    );
  } else if (stepUpState === 'CHALLENGE') {
    return (
      <div className='h-content w-content bg-white rounded main-content-shadow'>
        <StepUpContainer
          setStepUpState={setStepUpState}
          stepUpData={stepUpData}
        />
      </div>
    );
  } else {
    return (
      <div className='h-content w-content bg-white rounded main-content-shadow overflow-y-auto'>
        {isUserFederated ? (
          <div className='my-12 mx-16 flex flex-row gap-1 items-center'>
            <ExclamationCircleIcon className='w-4 h-4 text-awesome-red' />
            <span className='text-eerie-black text-lg font-semibold'>
              Resource not available for federated users
            </span>
          </div>
        ) : (
          <FactorsContainer
            factorsData={stepUpData}
            setFactorsData={setStepUpData}
          />
        )}
      </div>
    );
  }
};

export default SecurityContainer;
