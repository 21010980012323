export const getSettings = async () => {
  try {
    const response = await fetch(`/api/clientConfiguration/getSettings`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      throw response;
    }
    let res = await response.json();
    res = JSON.parse(res);
    return res
  } catch (error) {
    console.log(error);
    return null;
  }
};
