import React, { useState, useEffect } from 'react';
import StartChallenge from './StartChallenge';
import GaVerifyChallenge from './google-auth/VerifyChallenge';
import EmailVerifyChallenge from './email/VerifyChallenge';
import ChallengeSelection from './ChallengeSelection';

const StepUpContainer = ({ setStepUpState, stepUpData }) => {
  const [challengeState, setChallengeState] = useState('START');

  useEffect(() => {
    async function setState() {
      const challengeStateObject = JSON.parse(
        window.localStorage.getItem('step-up-data')
      );
      const now = new Date().getTime(); // Getting current time in millis
      if (challengeStateObject === null || challengeStateObject.expiry < now) {
        if (Object.keys(stepUpData).length >= 1) {
          setChallengeState('START');
        } else {
          setChallengeState('CHALLENGE_ERROR');
        }
      } else {
        setChallengeState(challengeStateObject.state);
      }
    }
    setState();
  }, []);

  useEffect(() => {
    const challengeStateObject = {
      state: challengeState,
      expiry: new Date().getTime() + 300000,
    };
    window.localStorage.setItem(
      'step-up-data',
      JSON.stringify(challengeStateObject)
    );
  }, [challengeState]);

  if (challengeState === 'START') {
    return (
      <StartChallenge
        setChallengeState={setChallengeState}
        stepUpData={stepUpData}
      />
    );
  } else if (challengeState === 'FACTOR_SELECTION') {
    return (
      <ChallengeSelection
        setChallengeState={setChallengeState}
        stepUpData={stepUpData}
      />
    );
  } else if (challengeState === 'GA_VERIFY') {
    return (
      <GaVerifyChallenge
        setChallengeState={setChallengeState}
        setStepUpState={setStepUpState}
        stepUpData={stepUpData}
      />
    );
  } else if (challengeState === 'EMAIL_VERIFY') {
    return (
      <EmailVerifyChallenge
        setChallengeState={setChallengeState}
        setStepUpState={setStepUpState}
        stepUpData={stepUpData}
      />
    );
  } else {
    // TODO: This should be a generic error screen
    return <div>Error</div>;
  }
};

export default StepUpContainer;
