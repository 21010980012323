import React from 'react';
import UserIcon from './UserIcon'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const ProfileImage = ({
  src,
  size,
  alt,
  color,
  border,
  borderColor,
  upload,
  iconColor
}) => {

  return (
    <div
      className={classNames(
        'items-center justify-center flex rounded-full border',
        size==='big'?'w-[100px] h-[100px]':'w-7 h-7',
        color,
        border ? borderColor : ''
      )}
    >
      {src !== '' ? (
        <img
          className={classNames('rounded-full',size==='big'?'w-[100px] h-[100px]':'w-7 h-7')}
          src={src}
          alt={alt}
        />
      ) : (
        <UserIcon className={classNames(iconColor,size==='big'?'w-12 h-12':'w-4 h-4',)} />
      )}
    </div>
  );
};

ProfileImage.defaultProps = {
  color: 'bg-neon-silver',
  border: false,
  borderColor: '',
  upload: false,
};

export default ProfileImage;
