import React, { useEffect, useState } from 'react';
import { useOktaAuth, SecureRoute } from '@okta/okta-react';
import ProfileContainer from './profile-settings/ProfileContainer';
import SecurityContainer from './security-settings/SecurityContainer';
import PasswordContainer from './password-settings/Container';
import SideNav from './shared/SideNav';
import Header from './shared/Header';
import Footer from './shared/footer/Footer';
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import 'animate.css';
import { isUserFederated } from '../helpers/profile';
import LoadingDialog from './shared/LoadingDialog';

export const UserContext = React.createContext({});
export const AccessTokenContext = React.createContext('');
export const IsUserFederatedContext = React.createContext(false);

const MainContent = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [userFederated, setUserFederatd] = useState(null);

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      setUserInfo(null);
      setAccessToken(null);
    } else {
      oktaAuth.getUser().then((info) => {
        setUserInfo(info);
        getFederation(info, oktaAuth.getAccessToken());
      });
      setAccessToken(oktaAuth.getAccessToken());
    }
  }, [authState, oktaAuth]); // Update if authState changes

  async function getFederation(info, token) {
    let isFederated = await isUserFederated(info, token);
    setUserFederatd(isFederated);
  }

  return (
    <UserContext.Provider value={userInfo}>
      <AccessTokenContext.Provider value={accessToken}>
        <IsUserFederatedContext.Provider value={userFederated}>
          <div className='bg-[#E5E5E5] min-h-screen'>
            <ReactNotifications className='mt-36' />
            <div className='grid grid-cols-1 grid-rows-layout gap-y-8'>
              <Header />
              <LoadingDialog
                open={userFederated === null}
                headerText='Loading'
              />

              <div className='grid grid-cols-content mx-auto'>
                <div className='flex gap-x-6 h-content'>
                  {userFederated === null ? <></> : <SideNav />}
                  <main id='main'>
                    <SecureRoute path='/profile' component={ProfileContainer} />
                    <SecureRoute path='/password' component={PasswordContainer} />
                    <SecureRoute path='/security' component={SecurityContainer} />
                  </main>
                </div>
              </div>

              <Footer />
            </div>
          </div>
        </IsUserFederatedContext.Provider>
      </AccessTokenContext.Provider>
    </UserContext.Provider>
  );
};

export default MainContent;
