import React, { useContext, useState, useEffect } from 'react';
import { UserContext, AccessTokenContext } from '../../MainContent';
import { resetFactor } from '../../../helpers/mfaGoogleAuth';
import ConfirmDialog from '../../shared/ConfirmDialog';
import LoadingDialog from '../../shared/LoadingDialog';
import { TrashIcon } from '@heroicons/react/outline';
import { Store } from 'react-notifications-component';
import NotificationBody from '../../shared/NotificationBody';

const ResetFactor = ({
  setEnrollState,
  setGaStatus,
  factorsData,
  setFactorsData,
  factorsCount,
  setFactorsCount,
}) => {
  const userInfo = useContext(UserContext);
  const accessToken = useContext(AccessTokenContext);
  const [openDialog, setOpenDialog] = useState(false);
  const [openLoadingDialog, setOpenLoadingDialog] = useState(false);
  const [deleteFactor, setDeleteFactor] = useState(false);

  useEffect(() => {
    if (deleteFactor) {
      reset();
    }
  }, [deleteFactor]);

  async function reset() {
    try {
      setOpenLoadingDialog(true)
      const deleted = await resetFactor(userInfo, accessToken);
      if (deleted) {
              setOpenLoadingDialog(true)
        setGaStatus(false);
        setEnrollState('NOT_STARTED');
        let newData = factorsData;
        delete newData['googleAuth'];
        setFactorsData(newData);
        Store.addNotification({
          content: (
            <NotificationBody
              id='googleAuthDeleteNotification'
              text='Your changes are saved.'
            />
          ),
          container: 'top-right',
          dismiss: {
            duration: 30000,
            click: false,
          },
          animationIn: ['animate__animated', 'animate__fadeInRight'],
          animationOut: ['animate__animated', 'animate__fadeOutRight'],
          id: 'googleAuthDeleteNotification',
        });
        const prevFactorsCount = factorsCount;
        setFactorsCount(prevFactorsCount - 1);
      }
      setOpenLoadingDialog(false)
    } catch (error) {
      setOpenLoadingDialog(false)
      setEnrollState('ERROR');

    }
  }

  if (!(factorsCount >= 2)) return;

  return (
    <div data-testid='googleAuthReset' className='flex flex-col gap-3'>
      <ConfirmDialog
        open={openDialog}
        setOpen={setOpenDialog}
        setResponse={setDeleteFactor}
        headerText='Remove your Google Authenticator account?'
        bodyText='This will remove your Google Authenticator account permanently from 2-step verification.'
        id='resetGoogleAuthDialog'
      ></ConfirmDialog>
      <LoadingDialog
        id='resetSMSLoadingDialog'
        headerText='Processing'
        bodyText='Please wait a moment while we remove your factor'
        open={openLoadingDialog}
      />
      <button
        data-testid='googleAuthResetButton'
        className='text-blue-bay text-xs items-center flex flex-row gap-1 w-min
          focus:underline outline-blue-bay outline-offset-2 hover:underline rounded'
        onClick={() => {
          setOpenDialog(true);
        }}
      >
        <TrashIcon className='text-blue-bay w-4 h-4' />
        <span>remove</span>
      </button>
    </div>
  );
};

export default ResetFactor;
