import React, { useState, useEffect } from 'react';
import ReactCountryFlag from 'react-country-flag';
import Input from '../shared/Input';
import CountryDropdown from './CountryDropdown';
import { countryCodes } from '../../helpers/countryCodes';

const Attribute = ({
  attributeKey,
  attributeName,
  attributeVal,
  attributeType,
  editState,
  inputStatePair,
}) => {
  const [inputVal, setInputVal] = useState('');
  const [inputState, setInputState] = inputStatePair;

  useEffect(() => {
    // This resets the input value when the editState changes
    setInputVal('');
  }, [editState]);

  const handleInputChange = (event) => {
    setInputVal(event.target.value);
    const newInputState = inputState;
    if (event.target.value === '' || event.target.value === attributeVal) {
      delete newInputState[attributeKey];
    } else {
      newInputState[attributeKey] = event.target.value;
    }
    setInputState(newInputState);
  };

  const handleDropdownChange = (selectedCountry) => {
    const newInputState = inputState;
    const selectedCountryCode = selectedCountry.code;
    if (attributeVal === selectedCountryCode) {
      delete newInputState[attributeKey];
    } else {
      newInputState[attributeKey] = selectedCountryCode;
    }
    setInputState(newInputState);
  }

  if (!editState) {
    return (
      <div className='flex flex-col gap-0.5 text-lg'>
        <dt className='basis-full text-granite-gray'>
          {attributeName}
        </dt>
        <dd
          data-testid={attributeKey + 'Val'}
          className='font-semibold'>{attributeName === 'Country' ?
            <div>
              <ReactCountryFlag 
                countryCode={attributeVal} 
                svg
                style={{
                  width: '24px',
                  height: '12px',
                  marginBottom: '3px',
                  marginRight:'4px'
                }}
                alt={`Country flag for ${countryCodes[attributeVal].name}`}
              />
              {countryCodes[attributeVal].name}
            </div> :
            attributeVal
          }
        </dd>
      </div>
    );
  } else {
    return (
      <div className='flex flex-col gap-1'>
        <span className='text-lg text-granite-gray'>
          {attributeName}
        </span>
        <div>
          {attributeType === 'dropdown' ? 
            <div>
              <CountryDropdown
                dropdownVal={attributeVal}
                onClickFunction={handleDropdownChange}
              />
            </div> :
            <div className='w-52'>
              <Input
                inputType='slim'
                inputValue={inputVal}
                inputPlaceholder={attributeVal}
                onChangeFunction={handleInputChange}
                disabledCondition={attributeKey === 'email'? true : false}
                error={false}
                errorMessage=''
                id={attributeKey}
              />
            </div>
          }
        </div>
      </div>
    );
  }
};

export default Attribute;
