export const saveProfileChanges = async (
  inputState,
  userInfo,
  accessToken
) => {

  if (Object.keys(inputState).length !== 0) {
    try {
      const formattedInfo = formatUserInfo(inputState);
      const response = await fetch(`/api/profile/${userInfo.sub}/update`, {
        method: 'PUT',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify(formattedInfo),
      });

      if (!response.ok) {
        throw 'ERROR';
      }
      return true;
    } catch (error) {
      return false;
    }
  }
};

export const isUserFederated = async (userInfo, accessToken) => {
  try {
    const response = await fetch(
      `/api/profile/${userInfo.sub}/isfederated`,
      {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
      }
    );

    let isFederated = await response.json();
    isFederated = JSON.parse(isFederated);
    if (!response.ok) {
      throw isFederated;
    }

    return isFederated.isFederated;
  } catch(error) {
    return false;
  }
};

const formatUserInfo = (userInfo) => {
  const mappings = {
    given_name: 'firstName',
    family_name: 'lastName',
  };

  const newUserInfo = {};
  for (const key in userInfo) {
    if (mappings.hasOwnProperty(key)) {
      const newKey = mappings[key];
      newUserInfo[newKey] = userInfo[key];
    } else {
      newUserInfo[key] = userInfo[key];
    }
  }
  return newUserInfo;
}