/**
 * This function checks if a user needs to be prompted for a step-up challenge
 * or not. If the user needs to be challenged, the active factors data is
 * returned as well.
 * @param {string} accessToken
 * @param {string} userId
 */
export const needsStepUp = async (accessToken, userId) => {
  try {
    const response = await fetch(`/api/stepup/${userId}/challenge`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw 'ERROR';
    }
    let resJson = await response.json();
    resJson = JSON.parse(resJson);
    return [resJson.challengeUser, resJson.activeFactorsData];
  } catch (error) {
    return [true, {}];
  }
};

/**
 * This function initiates the MFA challenge. In this step we send the password
 * to the user's factor.
 * @param {string} accessToken
 * @param {string} userId
 * @param {string} factorType
 */
export const challengeFactor = async (accessToken, userId, factorType) => {
  let challengeResponse = false;
  await fetch(`/api/stepup/${factorType}/${userId}/challenge`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`,
    },
  })
    .then(async (res) => {
      if (!res.ok) {
        throw 'There was an error while starting verification.';
      }
      challengeResponse = true;
    })
    .catch((error) => {
      console.log(error);
      challengeResponse = false;
    });

  return challengeResponse;
};

/**
 * This function sends the factor passcode to the server for verification.
 * @param {string} accessToken
 * @param {string} userId
 * @returns {boolean} Returns true or false depending on the verification result
 */
export const verifyFactor = async (
  accessToken,
  userId,
  passCode,
  factorType
) => {
  let verifiedSms = false;
  await fetch(`/api/stepup/${factorType}/${userId}/verify`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`,
    },
    body: JSON.stringify({
      passCode: passCode,
    }),
  })
    .then((res) => {
      if (res.ok) verifiedSms = true;
    })
    .catch((error) => {
      console.log(error);
    });

  return verifiedSms;
};
