/**
 * This function returns true if a google auth has been correctly configured
 * as a second factor. Returns false otherwise.
 */
export const gaEnroll = async (userInfo, accessToken) => {
  try {
    const response = await fetch(
      `/api/multifactor/googleauth/${userInfo.sub}/enroll`,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
      }
    );

    let qrLink = await response.json();
    qrLink = JSON.parse(qrLink);
    if (!response.ok) {
      throw qrLink;
    }

    return qrLink;
  } catch {
    return null;
  }
};

export const isGaEnrolled = async (userInfo, accessToken) => {
  try {
    const response = await fetch(
      `/api/multifactor/googleauth/${userInfo.sub}/details`,
      {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
      }
    );

    let isFactorEnrolled = await response.json();
    isFactorEnrolled = JSON.parse(isFactorEnrolled);
    if (!response.ok) {
      throw isFactorEnrolled;
    }

    return isFactorEnrolled;
  } catch {
    return { enrolled: false };
  }
};

export const gaActivate = async (userInfo, accessToken, activationCode) => {
  try {
    const response = await fetch(
      `api/multifactor/googleauth/${userInfo.sub}/activate`,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          code: activationCode,
        }),
      }
    );

    let responseJson = await response.json();
    responseJson = JSON.parse(responseJson);
    if (!response.ok) {
      throw responseJson;
    }
    return true;
  } catch (error) {
    if (error.error === 'ERROR: invalid code') {
      return false;
    } else {
      throw 'ERROR';
    }
  }
};

export const optIntoGa = async (userInfo, accessToken) => {
  try {
    const response = await fetch(
      `/api/multifactor/googleauth/${userInfo.sub}/opt-in`,
      {
        method: 'PUT',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
      }
    );

    if (!response.ok) {
      throw 'ERROR.';
    }
    return true;
  } catch (error) {
    return false;
  }
};

export const optOutOfGa = async (userInfo, accessToken) => {
  try {
    const response = await fetch(
      `/api/multifactor/googleauth/${userInfo.sub}/opt-out`,
      {
        method: 'DELETE',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
      }
    );

    if (!response.ok) {
      throw 'ERROR.';
    }
    return true;
  } catch (error) {
    return false;
  }
};

export const resetFactor = async (userInfo, accessToken) => {
  try {
    const response = await fetch(
      `/api/multifactor/googleauth/${userInfo.sub}/reset`,
      {
        method: 'DELETE',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          optOut: true,
        }),
      }
    );

    if (!response.ok) {
      throw response;
    }
    return true;
  } catch (error) {
    console.log(error);
    throw 'ERROR';
  }
};
