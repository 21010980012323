import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../MainContent';
import { maskEmail } from '../../../helpers/mfaEmail';
import ResetFactor from './ResetFactor';

const ActiveFactor = ({
  setEnrollState,
  setEmailStatus,
  factorsData,
  setFactorsData,
  factorsCount,
  setFactorsCount
}) => {
  const [date, setDate] = useState('');

  const userInfo = useContext(UserContext);

  useEffect(() => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    const tmpDate = new Date(factorsData.email.enrolledDate);
    setDate(tmpDate.toLocaleDateString('en-US', options));
  }, []);

  return (
    <div data-testid='emailFactorContainer' className='flex flex-col mt-1'>
      <div className='flex flex-row gap-2 items-center'>
        <span
          data-testid='emailFactorSpan'
          className='text-base text-carbon font-semibold'
        >
          {factorsData.email.hasOwnProperty('maskedEmail')
            ? factorsData.email.maskedEmail
            : maskEmail(userInfo.email)}
        </span>
        <span className='text-[#3C801F] text-sm h-min'>Verified</span>
      </div>
      <span data-testid='emailEnrolledDateSpan' className='text-carbon text-sm'>
        Added {date}
      </span>
      <ResetFactor
        setEnrollState={setEnrollState}
        setEmailStatus={setEmailStatus}
        factorsData={factorsData}
        setFactorsData={setFactorsData}
        factorsCount={factorsCount}
        setFactorsCount={setFactorsCount}
      />
    </div>
  );
};

export default ActiveFactor;
