import React, { useEffect, useState } from 'react';
import GAFactorContainer from './google-auth/FactorContainer';
import EmailFactorContainer from './email/FactorContainer';

const FactorsContainer = ({ factorsData, setFactorsData }) => {
  const [gaState, setGaState] = useState(false);
  const [emailState, setEmailState] = useState(false);
  const [isEnrolling, setIsEnrolling] = useState([]);
  const [factorsCount, setFactorsCount] = useState(0);
  const [factorIsLoading, setFactorIsLoading] = useState(false);

  useEffect(() => {
    let activeFactorsCount = 0;
    for (let factorKey in factorsData) {
      const factor = factorsData[factorKey];
      if (factorKey === 'googleAuth' && factor.status === 'ACTIVE') {
        setGaState(true);
        activeFactorsCount++;
      } else if (factorKey === 'email' && factor.status === 'ACTIVE') {
        setEmailState(true);
        activeFactorsCount++;
      }
      setFactorsCount(activeFactorsCount);
    }
  }, [factorsData]);

  return (
    <div className='flex flex-col'>
      <div className='border-b border-platinum'>
        <div className='mt-12 mb-9 mx-16 flex flex-row justify-between '>
          <div className=' flex flex-col'>
            <h1
              data-testid='securitySectionTitle'
              className='text-content-headline text-eerie-black font-bold font-neusa'
            >
              Security
            </h1>

            <span className='text-base w-[587px] text-granite-gray mt-1 font-semibold'>
              Protect your account with 2-step verification. Each time you sign
              in to your F5 account, you'll need your password and a
              verification code.
            </span>
          </div>
        </div>
      </div>
      <EmailFactorContainer
        isEnrolling={isEnrolling}
        setIsEnrolling={setIsEnrolling}
        emailStatus={emailState}
        setEmailStatus={setEmailState}
        factorsData={factorsData}
        setFactorsData={setFactorsData}
        factorsCount={factorsCount}
        setFactorsCount={setFactorsCount}
        loading={factorIsLoading}
        setLoading={setFactorIsLoading}
      />
      <GAFactorContainer
        isEnrolling={isEnrolling}
        setIsEnrolling={setIsEnrolling}
        gaStatus={gaState}
        setGaStatus={setGaState}
        factorsData={factorsData}
        setFactorsData={setFactorsData}
        factorsCount={factorsCount}
        setFactorsCount={setFactorsCount}
        loading={factorIsLoading}
        setLoading={setFactorIsLoading}
      />
    </div>
  );
};

export default FactorsContainer;
