import React, { useContext } from 'react';
import ProfileAttributes from './ProfileAttributes';
import { IsUserFederatedContext } from '../MainContent';

const ProfileContainer = () => {
  const isUserFederated = useContext(IsUserFederatedContext);
  if (isUserFederated === null) return <></>;
  return (
    <div className='h-content w-content bg-white rounded main-content-shadow'>
      <ProfileAttributes />
    </div>
  );
};

export default ProfileContainer;
