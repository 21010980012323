import { useEffect, useState, useContext } from 'react';
import { gaActivate, gaEnroll } from '../../../helpers/mfaGoogleAuth';
import { UserContext, AccessTokenContext } from '../../MainContent';
import Button from '../../shared/Button';
import Input from '../../shared/Input';
import LoadingDialog from '../../shared/LoadingDialog';
import { ExternalLinkIcon } from '@heroicons/react/outline';
import LoadingSpinner from '../../shared/LoadingSpinner';
import SetupKeyPopup from './SetupKeyPopup';
import { Store } from 'react-notifications-component';
import NotificationBody from '../../shared/NotificationBody';

const Enrollment = ({
  setEnrollState,
  factorsData,
  setFactorsData,
  factorsCount,
  setFactorsCount,
}) => {
  const userInfo = useContext(UserContext);
  const accessToken = useContext(AccessTokenContext);
  const [qrCode, setQrCode] = useState('');
  const [activationCode, setActivationCode] = useState('');
  const [invalidCode, setInvalidCode] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openQrInstructions, setOpenQrInstructions] = useState(false);
  const [qrSecret, setQrSecret] = useState('');

  const handleInputChange = (event) => {
    setActivationCode(event.target.value);
  };

  useEffect(() => {
    async function getQrCode() {
      if (userInfo !== null && accessToken !== null) {
        const response = await gaEnroll(userInfo, accessToken);
        if (response !== null) {
          setQrCode(response.qrLink);
          setQrSecret(response.secret);
        } else {
          setEnrollState('ERROR');
        }
      }
    }
    getQrCode();
  }, [userInfo, accessToken]);

  const verify = async () => {
    setOpenDialog(true);
    try {
      const activated = await gaActivate(userInfo, accessToken, activationCode);
      if (activated) {
        setEnrollState('ACTIVE');
        let newData = factorsData;
        newData.googleAuth = {
          status: 'ACTIVE',
          enrolledDate: new Date(),
        };
        setFactorsData(newData);
        const prevFactorsCount = factorsCount;
        setFactorsCount(prevFactorsCount + 1);

        Store.addNotification({
          content: (
            <NotificationBody
              id='googleAuthEnrollNotification'
              text='Your changes are saved.'
            />
          ),
          container: 'top-right',
          dismiss: {
            duration: 30000,
            click: false,
          },
          animationIn: ['animate__animated', 'animate__fadeInRight'],
          animationOut: ['animate__animated', 'animate__fadeOutRight'],
          id: 'googleAuthEnrollNotification',
        });
      } else {
        setInvalidCode(true);
      }
    } catch (error) {
      setEnrollState('ERROR');
    }
    setOpenDialog(false);
  };

  return (
    <div
      data-testid='googleAuthEnrollment'
      className='flex flex-col mt-2 gap-4'
    >
      <div className='flex flex-row gap-6'>
        <span className='text-base text-granite-gray'>
          <b>Step 1</b>
        </span>
        <div className='flex flex-col gap-5'>
          <span className='text-base text-carbon w-[447px] font-semibold'>
            First, install Google Authenticator mobile app on your phone.
          </span>
          <div className='flex flex-row gap-3'>
            <Button
              buttonType='outlined'
              buttonText={
                <div className='flex flex-row gap-1'>
                  <span>Go to App Store </span>
                  <ExternalLinkIcon className='w-4 h-4' />
                </div>
              }
              onClickFunction={() => {
                window
                  .open(
                    'https://apps.apple.com/us/app/google-authenticator/id388497605',
                    '_blank'
                  )
                  .focus();
              }}
            ></Button>
            <Button
              buttonType='outlined'
              buttonText={
                <div className='flex flex-row gap-1'>
                  <span>Go to Google Play </span>
                  <ExternalLinkIcon className='w-4 h-4' />
                </div>
              }
              onClickFunction={() => {
                window
                  .open(
                    'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2',
                    '_blank'
                  )
                  .focus();
              }}
            ></Button>
          </div>
        </div>
      </div>
      <div className='flex flex-row gap-6'>
        <span className='text-base text-granite-gray'>
          <b>Step 2</b>
        </span>
        <div className='flex flex-col'>
          <span className='text-base text-carbon font-semibold'>
            In the Google Authenticator app, tab the +.
          </span>
          <span className='text-base text-carbon font-semibold'>
            Choose <b>Scan a QR code</b>.
          </span>
          <div className='flex flex-row'>
            {qrCode !== '' ? (
              <img
                data-testid='qrCode'
                className='w-[120px] h-[120px]'
                src={qrCode}
              ></img>
            ) : (
              <LoadingSpinner height='h-20' width='w-20' margin='' />
            )}
            <a
              href={null}
              data-testid='qrSecretLink'
              onClick={() => setOpenQrInstructions(true)}
              className='my-auto text-blue-bay text-xs font-medium
                cursor-pointer hover:underline underline-offset-2 rounded
                focus:underline outline-blue-bay outline-offset-2'
              tabIndex='0'
            >
              Can't scan the QR code?
            </a>
          </div>
        </div>
        <SetupKeyPopup
          open={openQrInstructions}
          setOpen={setOpenQrInstructions}
          qrSecret={qrSecret}
        />
      </div>
      <div className='flex flex-row gap-6'>
        <span className='text-base text-granite-gray'>
          <b>Step 3</b>
        </span>
        <div className='flex flex-col gap-3'>
          <span className='text-base text-carbon font-semibold'>
            Enter the 6-digit code you see in the app.
          </span>
          <div className='flex flex-col'>
            <span className='text-sm text-granite-gray'>Verification Code</span>
            <div className='flex flex-row gap-3'>
              <Input
                inputType='slim'
                inputValue={activationCode}
                inputPlaceholder='123456'
                onChangeFunction={handleInputChange}
                error={invalidCode}
                errorMessage='Not a correct verification code'
                id='googleAuthValidationCodeInput'
              ></Input>

              <Button
                id='googleAuthVerifyButton'
                buttonType='filled'
                buttonText='Verify'
                disableCondition={!activationCode}
                onClickFunction={() => {
                  verify();
                }}
              ></Button>
              <LoadingDialog
                open={openDialog}
                bodyText='Please wait a moment as we verify'
                headerText='Verifying'
                id='googleAuthEnrollmentLoadingDialog'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Enrollment;
