import react from 'react';

const Error = () => {
  return (
    <div className='text-base text-carbon'>
      <h2>An Unexpected Error happened...</h2>
    </div>
  );
};

export default Error;
