import React, { useState, useContext } from 'react';
import Initial from './Initial';
import Change from './Change';
import Error from './Error';
import { IsUserFederatedContext } from '../MainContent';
import { ExclamationCircleIcon } from '@heroicons/react/solid';

const PasswordContainer = () => {
  const [state, setState] = useState('INITIAL');
  const isUserFederated = useContext(IsUserFederatedContext);

  if (isUserFederated === null) return <></>;
  return (
    <div className='h-content w-content bg-white rounded main-content-shadow'>
      <div className='flex flex-col'>
        <div className='border-b border-platinum'>
          <div className='my-8 mx-16 flex flex-row justify-between '>
            <h1
              data-testid='passwordSectionTitle'
              className='text-content-headline text-eerie-black font-bold font-neusa'
            >
              Password
            </h1>
          </div>
        </div>
        {isUserFederated ? (
          <div
            data-testid='passwordContainerFederatedMessage'
            className='my-12 mx-16 flex flex-row gap-1 items-center'
          >
            <ExclamationCircleIcon className='w-4 h-4 text-awesome-red' />
            <span className='text-eerie-black text-lg font-semibold'>
              Resource not available for federated users
            </span>
          </div>
        ) : state === 'INITIAL' ? (
          <Initial setState={setState}></Initial>
        ) : state === 'CHANGE' ? (
          <Change setState={setState}></Change>
        ) : (
          <Error></Error>
        )}
      </div>
    </div>
  );
};

export default PasswordContainer;
