import React from 'react';
import LoadingSpinner from './LoadingSpinner';
import { FocusTrap } from '@headlessui/react';

const LoadingDialog = ({ open, bodyText, headerText, id }) => {
  if (open) {
    return (
      <div
        data-testid={id}
        className='fixed top-0 left-0 bg-[#E5E5E5] bg-opacity-50 w-full h-full
          outline-none overflow-hidden flex justify-center items-center z-50'
      >
        <FocusTrap>
          <div className='w-[400px]'>
            <div
              className='border-none shadow-dialog relative flex flex-col w-full 
              bg-white rounded outline-none'
            >
              <div className='flex items-center justify-center mt-6'>
                <LoadingSpinner />
              </div>
              <div className='flex px-6 py-3 justify-center'>
                <span
                  data-testid={id + '-dialogHeaderText'}
                  className='text-xl font-semibold text-eerie-black '
                >
                  {headerText}
                </span>
              </div>
              <div className='flex px-6 pb-6 justify-center'>
                <span
                  data-testid={id + '-dialogBodyText'}
                  className='text-base text-eerie-black text-center'
                >
                  {bodyText}
                </span>
              </div>
            </div>
          </div>
        </FocusTrap>
      </div>
    );
  }
};

export default LoadingDialog;
