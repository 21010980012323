import React, { useState } from 'react';
import Input from '../../shared/Input';

const ChallengeInput = ({ setOtpState, inputError }) => {
  const [inputVal, setInputVal] = useState('');

  const handleInputChange = (event) => {
    setInputVal(event.target.value.trim());
    setOtpState(event.target.value.trim());
  };

  return (
    <div className='flex flex-col px-16'>
      <span className='text-base text-carbon font-semibold'>
        Enter authentication code
      </span>
      <div className='w-52'>
        <Input
          inputType={'slim'}
          inputValue={inputVal}
          inputPlaceholder={'123456'}
          onChangeFunction={handleInputChange}
          disabledCondition={false}
          error={inputError}
          errorMessage={'Invalid authentication code'}
          id={'stepUpEmailInput'}
        />
      </div>
    </div>
  );
}

export default ChallengeInput;