import React from 'react';

const Button = ({
  buttonType,
  buttonText,
  disableCondition,
  onClickFunction,
  id,
}) => {
  if (buttonType === 'filled') {
    return (
      <button
        data-testid={id}
        className='text-base leading-5 text-white bg-blue-bay py-2 px-4
          disabled:bg-spanish-gray disabled:cursor-not-allowed h-min
          disabled:hover:no-underline hover:bg-electric-blue hover:underline
          underline-offset-4 rounded active:bg-medium-electric-blue
          focus:ring-electric-blue focus:ring-offset-2 
          focus:ring-2 focus:outline-none disabled:border-spanish-gray
          border border-blue-bay hover:border-electric-blue
          active:border-medium-electric-blue focus:drop-shadow-blue-bay'
        disabled={disableCondition}
        onClick={onClickFunction}
      >
        {buttonText}
      </button>
    );
  } else {
    return (
      <button
        data-testid={id}
        className='text-base leading-5 text-blue-bay bg-white border 
          border-blue-bay py-2 px-4 rounded hover:bg-blue-bay
          hover:text-white hover:underline underline-offset-4 h-min
          active:bg-medium-electric-blue active:text-white
          disabled:border-neon-silver disabled:cursor-not-allowed
          disabled:hover:bg-white disabled:text-granite-gray
          disabled:hover:no-underline focus:ring-1 focus:outline-none
          focus:ring-blue-bay focus:drop-shadow-blue-bay'
        disabled={disableCondition}
        onClick={onClickFunction}
      >
        {buttonText}
      </button>
    );
  }
};

export default Button;
