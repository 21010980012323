import React, { useState, useContext } from 'react';
import Button from '../../shared/Button';
import { UserContext, AccessTokenContext } from '../../MainContent';
import LoadingSpinner from '../../shared/LoadingSpinner';
import { emailEnroll, maskEmail } from '../../../helpers/mfaEmail';


const EnrollStepOne = ({ setEnrollState }) => {
  const [buttonText, setButtonText] = useState('Send Code');
  const [isButtonActive, setIsButtonActive] = useState(true);
  const userInfo = useContext(UserContext);
  const accessToken = useContext(AccessTokenContext);

  const startTimer = (
    secondsToActive,
    setButtonText,
    setIsButtonActive,
  ) => {
    const countDownTime = new Date().getTime() + secondsToActive * 1000;
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const difference = countDownTime - now;
      const seconds = Math.floor((difference / 1000) % 60);
      if (seconds === 0) {
        clearInterval(interval);
        setButtonText('Resend Code');
        setIsButtonActive(true);
      } else {
        setButtonText(`Resend (${seconds}s) `);
      }
    }, 100);
  };

  const enroll = async () => {
    try {
      setIsButtonActive(false);
      setButtonText(
        <div className='flex flex-row'>
          {'Sending Code'}
          <LoadingSpinner
            id='emailEnrollLoadingSpinner'
            height={'h-4 '}
            width={'w-4 '}
            margin={'my-auto ml-1 '}
          />
        </div>
      );
      const response = await emailEnroll(userInfo, accessToken);
      setEnrollState(response['status']);
      startTimer(30, setButtonText, setIsButtonActive);
    } catch (error) {
      setEnrollState('ERROR');
    }
  }

  return (
    <div data-testid='emailEnrollment' className='flex flex-row gap-6'>
      <span className='text-base text-carbon font-bold'>Step 1</span>
      <div className='flex flex-col gap-3'>
        <span className='text-base text-carbon font-bold'>
          Click on the button to send a verification code to your email
        </span>
        <div className='flex flex-row'>
          <div className='my-auto'>
            {maskEmail(userInfo.email)}
          </div>
          <div className='pl-2'>
            <Button
              id='emailEnrollButton'
              buttonType='outlined'
              buttonText={buttonText}
              disableCondition={!(isButtonActive)}
              onClickFunction={() => enroll()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnrollStepOne;