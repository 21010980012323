import React, { useEffect } from 'react';
import Button from './Button';
import { FocusTrap } from '@headlessui/react';

const ConfirmDialog = ({
  open,
  setOpen,
  setResponse,
  bodyText,
  headerText,
  id,
}) => {
  useEffect(() => {
    if (open) {
      setResponse(false);
    }
  }, [open]);

  if (open) {
    return (
      <div
        data-testid={id}
        className='fixed top-0 left-0 bg-[#E5E5E5] bg-opacity-50 w-full h-full
          outline-none overflow-hidden flex justify-center items-center z-50'
      >
        <FocusTrap>
          <div className='w-[440px]'>
            <div
              className='border-none shadow-dialog relative flex flex-col w-full 
              bg-white rounded outline-none'
            >
              <div
                className='flex flex-shrink-0 items-center 
                justify-between px-8 py-4 border-b border-platinum rounded-t'
              >
                <span
                  data-testid={id + '-dialogHeaderText'}
                  className='text-lg text-carbon'
                >
                  {headerText}
                </span>
              </div>
              <div className='relative px-8 pt-6'>
                <span
                  data-testid={id + '-dialogBodyText'}
                  className='text-base text-carbon'
                >
                  {bodyText}
                </span>
              </div>
              <div className='flex flex-row gap-3 items-center justify-end py-6 px-5'>
                <Button
                  id={id + '-cancelButton'}
                  buttonType='outline'
                  buttonText='Cancel'
                  onClickFunction={() => {
                    setOpen(false);
                    setResponse(false);
                  }}
                ></Button>
                <Button
                  id={id + '-confirmButton'}
                  buttonType='filled'
                  buttonText='Confirm'
                  onClickFunction={() => {
                    setOpen(false);
                    setResponse(true);
                  }}
                ></Button>
              </div>
            </div>
          </div>
        </FocusTrap>
      </div>
    );
  }
};

export default ConfirmDialog;
