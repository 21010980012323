import React from 'react';
import { ExclamationCircleIcon, CheckCircleIcon } from '@heroicons/react/solid';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Input = ({
  inputType,
  inputValue,
  inputPlaceholder,
  onChangeFunction,
  disabledCondition,
  error,
  errorMessage,
  borderRadius,
  validations,
  id,
}) => {
  let validationError = false;
  let validationsDisplay = [];
  if (
    validations !== undefined &&
    validations !== null &&
    Object.keys(validations).length > 0
  ) {
    for (let key in validations) {
      validationError = validationError || !validations[key].state;
      let state = validations[key].state ? 'pass' : 'fail';
      validationsDisplay.push(
        <div
          data-testid={id + '-' + key + '-' + state}
          key={key}
          className='flex flex-row'
        >
          {validations[key].state ? (
            <CheckCircleIcon className='h-3 w-3 my-auto text-emerald' />
          ) : (
            <ExclamationCircleIcon className='h-3 w-3 my-auto text-awesome-red' />
          )}
          <div className='text-sm text-carbon'>
            {validations[key].message}
          </div>
        </div>
      );
    }
  }

  if (inputType === 'slim' || inputType === 'slim-password') {
    return (
      <div className='flex flex-col'>
        <input
          id={id}
          data-testid={id}
          style={{ outline: '2px solid white', outlineOffset: '0px' }}
          className={classNames(
            `text-base placeholder:text-spanish-gray text-carbon
            bg-white px-[10px] py-[6px] border max-w-[240px]
            hover:border-blue-bay disabled:hover:border-neon-silver
            disabled:bg-white-smoke disabled:cursor-not-allowed
            border-neon-silver disabled:text-spanish-gray ${borderRadius} `,
            !(error || validationError)
              ? `focus:border-blue-bay focus:drop-shadow-blue-bay `
              : `focus:drop-shadow-awesome-red border-awesome-red 
                hover:border-awesome-red focus:border-awesome-red`
          )}
          type={inputType === 'slim-password' ? 'password' : 'text'}
          placeholder={inputPlaceholder}
          disabled={disabledCondition}
          value={inputValue}
          onChange={onChangeFunction}
        />
        {error ? (
          <div
            data-testid={id + '-message'}
            className='flex flex-row gap-px items-start'
          >
            <ExclamationCircleIcon className='h-3 w-3 my-auto text-awesome-red flex items-start' />
            <div className='text-sm text-carbon'>{errorMessage}</div>
          </div>
        ) : validationsDisplay.length > 0 ? (
          <div data-testid={id + '-message'} className='flex flex-col'>
            {validationsDisplay}
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  } else {
    return <div>TODO Input component</div>;
  }
};

Input.defaultProps = {
  borderRadius: 'rounded',
};

export default Input;
