import React, { useState } from 'react';
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Link = ({ text, link, onClick, type }) => {
  const [focusStyle, setFocusStyle] = useState('none');
  return (
    <a
      href={link}
      style={{
        outline: focusStyle,
      }}
      onBlur={() => {
        setFocusStyle('none');
      }}
      onFocus={() => {
        setFocusStyle(type == 'Header' ? '2px solid black' : '2px solid white');
      }}
      className={classNames(
        'rounded focus:underline hover:underline outline-offset-2',
        type == 'Footer'
          ? ' text-white text-base font-medium pt-1 underline-offset-4 '
          : type == 'Footer-Policy'
          ? 'text-white text-xs leading-3 underline-offset-2 '
          : 'text-base font-semibold text-black'
      )}
    >
      <span onClick={onClick}>{text}</span>
    </a>
  );
};
export default Link;
