import React from 'react';
import { useState } from 'react';
import Button from '../shared/Button';
import LoadingDialog from '../shared/LoadingDialog';

const StartChallenge = ({ setChallengeState, stepUpData }) => {
  const [open, setOpen] = useState(false);

  const startChallenge = () => {
    if (Object.keys(stepUpData).length > 1) {
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
        setChallengeState('FACTOR_SELECTION');
      },500);
    } else if (Object.keys(stepUpData).length === 1) {
      if (stepUpData.hasOwnProperty('sms')) {
        setChallengeState('SMS_VERIFY');
      } else if (stepUpData.hasOwnProperty('googleAuth')) {
        setChallengeState('GA_VERIFY');
      } else if (stepUpData.hasOwnProperty('email')) {
        setChallengeState('EMAIL_VERIFY');
      }
    } else {
      setChallengeState('CHALLENGE_ERROR');
    }
  };

  return (
    <div className='flex flex-col'>
      <LoadingDialog headerText='Loading' open={open}></LoadingDialog>
      <div className='grid grid-cols-step-up border-b border-platinum py-12 px-16'>
        <div className='flex flex-col'>
          <h1
            className='text-content-headline font-neusa font-bold text-eerie-black'
            data-testid='verifyText'
          >
            Verify to continue
          </h1>
          <span className='text-base font-semibold text-granite-gray'>
            You will access security information after verification.
          </span>
        </div>
        <div className='ml-auto'>
          <Button
            buttonType={'filled'}
            buttonText={'Verify'}
            disableCondition={false}
            onClickFunction={() => startChallenge()}
            id='verifyButton'
          />
        </div>
      </div>
    </div>
  );
};

export default StartChallenge;
