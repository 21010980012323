import _ from 'lodash';
export const updatePassword = async (
  userInfo,
  accessToken,
  oldPassword,
  newPassword
) => {
  try {
    const response = await fetch(`/api/password/${userInfo.sub}/change`, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        OldPassword: oldPassword,
        NewPassword: newPassword,
      }),
    });

    let responseJson = await response.json();
    responseJson = JSON.parse(responseJson);
    if (!response.ok) {
      throw responseJson;
    }

    return 'OK';
  } catch (error) {
    return error.error;
  }
};

export const checkUsername = (username, password) => {
  return !passwordContainsUserName(username, password);
};

export const checkRegex = (password, format) => {
  let regex = format;
  return RegExp(regex).test(password);
};

export const checkLength = (password, min, max) => {
  if (_.isNumber(min)) {
    if (password.length < min) {
      return false;
    }
  }

  if (_.isNumber(max)) {
    if (password.length > max) {
      return false;
    }
  }

  return true;
};

const passwordContainsUserName = (formField, password) => {
  if (!formField) {
    return false;
  }
  let emailDomain = getEmailDomain(formField.toLowerCase());
  formField = removeEmailAddressDomain(formField.toLowerCase());
  password = password.toLowerCase();
  const formFieldArr = getParts(formField);

  //check if each formField part contains password
  for (var i = 0; i < formFieldArr.concat(emailDomain).length; i++) {
    const formFieldPart = formFieldArr[i];

    if (password.indexOf(formFieldPart) !== -1) {
      return true;
    }
  }
  return false;
};

const getEmailDomain = (username) => {
  let atSignPos = username.indexOf('@');
  if (atSignPos === -1) return [];
  let dotPos = username.indexOf('.', atSignPos);
  if (dotPos === -1) return [];
  return [username.substring(atSignPos + 1, dotPos)];
};

const removeEmailAddressDomain = (username) => {
  if (username.includes('@')) {
    return username.split('@', 1)[0];
  }
  return username;
};

const getParts = function (username) {
  const usernameArr = username.split('');
  const minPartsLength = 3;
  const userNameParts = [];
  const delimiters = [',', '.', '-', '_', '#', '@'];
  let userNamePart = '';

  _.each(usernameArr, function (part) {
    if (delimiters.indexOf(part) === -1) {
      userNamePart += part;
    } else {
      if (userNamePart.length >= minPartsLength) {
        userNameParts.push(_.clone(userNamePart));
      }
      userNamePart = '';
    }
  });
  if (userNamePart.length >= minPartsLength) {
    userNameParts.push(_.clone(userNamePart));
  }
  return userNameParts.filter(Boolean);
};
