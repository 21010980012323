import React, { useState } from 'react';
import { Menu } from '@headlessui/react';
import ReactCountryFlag from 'react-country-flag';
import Dropdown from '../shared/Dropdown';
import { countryCodes } from '../../helpers/countryCodes';

const CountryDropdown = ({ dropdownVal, onClickFunction }) => {
  const [selectedCountry, setSelectedCountry] = useState(dropdownVal);

  const classNames = (...classes) => {
    return classes.filter(Boolean).join(' ');
  };

  const handleOnClick = (country) => {
    setSelectedCountry(country.code);
    onClickFunction(country);
  };

  const dropdownOptions = [];
  for (const countryCode in countryCodes) {
    const country = countryCodes[countryCode];
    const countryName = country.name;
    dropdownOptions.push(
      <Menu.Item key={countryCode}>
        {({ active }) => (
          <a
            href={null}
            onClick={() => handleOnClick(country)}
            className={classNames(
              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
              'block px-4 py-2 text-sm'
            )}
          >
            <ReactCountryFlag
              countryCode={countryCode}
              svg
              style={{
                width: '25px',
                height: '16px',
                marginBottom: '3px',
                paddingRight: '4px',
              }}
            />
            {countryName}
          </a>
        )}
      </Menu.Item>
    );
  }

  const dropDownTitle = (
    <div>
      <ReactCountryFlag
        countryCode={selectedCountry}
        svg
        style={{
          width: '25px',
          height: '16px',
          marginBottom: '3px',
          paddingRight: '4px',
        }}
      />
      {countryCodes[selectedCountry]?.name}
    </div>
  );

  return (
    <div>
      <Dropdown
        dropdownTitle={dropDownTitle}
        dropdownOptions={dropdownOptions}
        maxDropdownHeight={true}
        dropdownDisabled={true}
        type='content'
      />
    </div>
  );
};

export default CountryDropdown;
