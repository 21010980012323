import React, { useEffect, useState } from 'react';
import Button from '../shared/Button';

const ChallengeSelection = ({ setChallengeState, stepUpData }) => {
  const [factorButtons, setFactorButtons] = useState([]);

  useEffect(() => {
    let newFactorButtons = [];
    for (let key in stepUpData) {
      if (key === 'googleAuth') {
        newFactorButtons.push(
          createButton('Verify with Google Authenticator', 'GA_VERIFY', key)
        );
      } else if (key === 'email') {
        newFactorButtons.push(
          createButton('Verify with Email', 'EMAIL_VERIFY', key)
        );
      }
    }
    setFactorButtons(newFactorButtons);
  }, []);

  const createButton = (buttonMessage, challengeState, elementId) => {
    return (
      <div className='pt-4 px-16' key={elementId}>
        <Button
          buttonType={'filled'}
          buttonText={buttonMessage}
          disableCondition={false}
          onClickFunction={() => setChallengeState(challengeState)}
        />
      </div>
    );
  };

  return (
    <div className='flex flex-col'>
      <div className='border-b border-platinum py-8 px-16'>
        <h1 className='text-content-headline font-neusa font-bold text-eerie-black'>
          Verify to continue
        </h1>
      </div>
      <div
        className='py-8 px-16 text-lg text-carbon'
        data-testid={'multiFactorText'}
      >
        Select a verification method to continue
      </div>
      {factorButtons}
      <div className='py-12 px-16'>
        <Button
          buttonType={'outlined'}
          buttonText={'Back'}
          disableCondition={false}
          onClickFunction={() => setChallengeState('START')}
        />
      </div>
    </div>
  );
};

export default ChallengeSelection;
