import React, { useContext, useState, useEffect } from 'react';
import {
  UserContext,
  AccessTokenContext,
  IsUserFederatedContext,
} from '../MainContent';
import { saveProfileChanges } from '../../helpers/profile';
import Attribute from './Attribute';
import LoadingDialog from '../shared/LoadingDialog';
import Button from '../shared/Button';
import { Store } from 'react-notifications-component';
import NotificationBody from '../shared/NotificationBody';

const ProfileAttributes = () => {
  const userInfo = useContext(UserContext);
  const accessToken = useContext(AccessTokenContext);
  const isUserFederated = useContext(IsUserFederatedContext);
  const [localUserInfo, setLocalUserInfo] = useState(userInfo);
  const [loadingState, setLoadingState] = useState(false);
  const [editState, setEditState] = useState(false);
  const [inputState, setInputState] = useState({});

  useEffect(() => {
    setLocalUserInfo(userInfo);
  }, [userInfo]);

  const handleSave = async () => {
    setLoadingState(true);
    const response = await saveProfileChanges(
      inputState,
      userInfo,
      accessToken
    );
    if (response) {
      const newLocalUserInfo = userInfo;
      for (let key in inputState) {
        newLocalUserInfo[key] = inputState[key];
      }
      setLocalUserInfo(newLocalUserInfo);
      Store.addNotification({
        content: (
          <NotificationBody id='profile' text='Your changes are saved.' />
        ),
        container: 'top-right',
        dismiss: {
          duration: 30000,
          click: false,
        },
        animationIn: ['animate__animated', 'animate__fadeInRight'],
        animationOut: ['animate__animated', 'animate__fadeOutRight'],
        id: 'profile',
      });
    }
    setLoadingState(false);
    setEditState(false);
  };

  const editButton = (
    <div className='pt-4'>
      <Button
        buttonType='outline'
        buttonText={'Edit My Profile'}
        onClickFunction={() => setEditState(true)}
        id='startProfileEdit'
      />
    </div>
  );

  const saveButtons = (
    <div className='flex flex-row gap-3 pt-4'>
      <Button
        buttonType='outline'
        buttonText={'Cancel'}
        onClickFunction={() => setEditState(false)}
        id='cancelProfileAttributes'
      />
      <Button
        buttonType='filled'
        buttonText={'Save'}
        onClickFunction={() => handleSave()}
        id='saveProfileAttributes'
      />
    </div>
  );

  const profileAttributeName = {
    given_name: ['First Name', 0],
    family_name: ['Last Name', 1],
    email: ['Email', 2],
    country: ['Country', 3],
  };

  if (!userInfo) {
    return (
      <div className='grid grid-cols-profile-content gap-y-6 gap-x-6 mx-16 my-12'>
        <LoadingDialog
          open={true}
          bodyText={'Please wait a moment while we load your data'}
          headerText={'Loading My Profile'}
        />
      </div>
    );
  } else {
    const profileAttributes = [];
    for (const key in localUserInfo) {
      if (profileAttributeName.hasOwnProperty(key)) {
        const attributeName = profileAttributeName[key][0];
        const attributeVal = localUserInfo[key];
        const attributeType = key === 'country' ? 'dropdown' : 'input';
        profileAttributes.unshift(
          <Attribute
            key={key}
            attributeKey={key}
            attributeName={attributeName}
            attributeVal={attributeVal}
            attributeType={attributeType}
            editState={editState}
            inputStatePair={[inputState, setInputState]}
          />
        );
      }
    }
    profileAttributes.sort((a, b) => {
      const attributeOneKey = a.props.attributeKey;
      const attributeTwoKey = b.props.attributeKey;
      const attributeOnePosition = profileAttributeName[attributeOneKey][1];
      const attributeTwoPosition = profileAttributeName[attributeTwoKey][1];
      return attributeOnePosition - attributeTwoPosition;
    });

    return (
      <div>
        <LoadingDialog
          open={loadingState}
          bodyText={'Please wait a moment while we save your changes'}
          headerText={'Saving'}
        />
        <div className='px-16 py-8 border-b border-platinum'>
          <h1
            data-testid='profileSectionTitle'
            className='font-bold text-content-headline font-neusa'
          >
            My Profile
          </h1>
        </div>
        <div className='flex flex-col gap-6 px-16 py-8'>
          <dl className='flex flex-col gap-6' aria-live='polite'>
            {profileAttributes}
          </dl>
          {isUserFederated ? <></> : editState ? saveButtons : editButton}
        </div>
      </div>
    );
  }
};

export default ProfileAttributes;
