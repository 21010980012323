import React, { useContext, useEffect, useState } from 'react';
import { UserContext, AccessTokenContext } from '../MainContent';
import Button from '../shared/Button';
import Input from '../shared/Input';
import {
  updatePassword,
  checkLength,
  checkRegex,
  checkUsername,
} from '../../helpers/password';
import LoadingDialog from '../shared/LoadingDialog';
import { Store } from 'react-notifications-component';
import NotificationBody from '../shared/NotificationBody';
import InfoDialog from '../shared/InfoDialog';

const Change = ({ setState }) => {
  const userInfo = useContext(UserContext);
  const accessToken = useContext(AccessTokenContext);
  const [user, setUser] = useState('User');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordTyped, setConfirmPasswordTyped] = useState(false);
  const [validPassword, setValidPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [validations, setValidations] = useState();
  const [passwordError, setPasswordError] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState(
    'Current password is a required field'
  );

  useEffect(() => {
    if (userInfo !== undefined && userInfo !== null) {
      setUser(userInfo.email);
    }
  }, [userInfo]);

  function saveAvailable() {
    if (newPassword === '') {
      return false;
    }
    if (password === '') {
      return false;
    }

    return validPassword && newPassword === confirmPassword;
  }

  async function changePassword() {
    setLoading(true);
    let response = await updatePassword(
      userInfo,
      accessToken,
      password,
      newPassword
    );
    if (response === 'OK') {
      setState('INITIAL');
      setErrorMessage('');
      Store.addNotification({
        content: (
          <NotificationBody
            id='passwordChangeNotification'
            text='Your changes are saved.'
          />
        ),
        container: 'top-right',
        dismiss: {
          duration: 30000,
          click: false,
        },
        animationIn: ['animate__animated', 'animate__fadeInRight'],
        animationOut: ['animate__animated', 'animate__fadeOutRight'],
        id: 'passwordChangeNotification',
      });
    } else {
      if (response !== 'An error happened during password change') {
        setError(true);
        setErrorMessage(response);
      } else {
        setState('ERROR');
      }
    }
    setLoading(false);
  }

  const handleInputChange = (event) => {
    setPassword(event.target.value);
    setErrorMessage('');
    if (event.target.value === '') {
      setPasswordError(true);
      setPasswordErrorMessage('Current password is a required field');
    } else {
      setPasswordError(false);
      setPasswordErrorMessage('');
    }
  };

  const handleInputChangeNew = (event) => {
    setNewPassword(event.target.value);
    setErrorMessage('');
    const newValidations = {
      length: {
        state: checkLength(event.target.value, 8, 72),
        message: 'At least 8 characters',
      },
      number: {
        state: checkRegex(event.target.value, /[\d]+/),
        message: 'At least 1 number',
      },
      lowercase: {
        state: checkRegex(event.target.value, /[a-z]+/),
        message: 'At least 1 lowercase letter',
      },
      uppercase: {
        state: checkRegex(event.target.value, /[A-Z]+/),
        message: 'At least 1 uppercase letter',
      },
      email: {
        state: checkUsername(user, event.target.value),
        message: 'Does not contains part of email',
      },
      existing: {
        state: event.target.value !== password,
        message: 'Must not match current password field',
      },
    };
    let valid = true;
    for (let key in newValidations) {
      valid = valid && newValidations[key].state;
    }
    setValidations(newValidations);
    setValidPassword(valid);
  };

  const handleInputChangeConfirm = (event) => {
    setConfirmPassword(event.target.value);
    setConfirmPasswordTyped(true)
    setErrorMessage('');
  };

  return (
    <div
      data-testid='passwordChange'
      className='my-8 mx-16 flex flex-col gap-6'
    >
      <LoadingDialog
        id='passwordChangeLoadingDialog'
        open={loading}
        bodyText='Please wait a moment as we save your changes'
        headerText='Saving'
      />
      <InfoDialog
        id='passwordChangeInfoDialog'
        open={error}
        setOpen={setError}
        bodyText={
          errorMessage + ' Please make the necessary changes and try again'
        }
        headerText='Changes could not be saved'
      />
      <div className='flex flex-col gap-0'>
        <span className='text-black text-sm'>
          You are changing your password for: <b>{user}</b>.
        </span>
      </div>
      <div className='flex flex-col gap-px '>
        <span className='text-base text-carbon font-semibold'>
          Current Password
        </span>
        <Input
          id='passwordChangeCurrentInput'
          inputType='slim-password'
          onChangeFunction={handleInputChange}
          error={passwordError}
          errorMessage={passwordErrorMessage}
        ></Input>
      </div>
      <div className='flex flex-col gap-px'>
        <span className='text-base text-carbon font-semibold'>
          New Password
        </span>
        <Input
          id='passwordChangeNewInput'
          inputType='slim-password'
          onChangeFunction={handleInputChangeNew}
          validations={validations}
        ></Input>
      </div>
      <div className='flex flex-col gap-px'>
        <span className='text-base text-carbon font-semibold'>
          Confirm Password
        </span>
        <Input
          id='passwordChangeConfirmInput'
          inputType='slim-password'
          onChangeFunction={handleInputChangeConfirm}
          error={newPassword !== confirmPassword  && confirmPasswordTyped ===true}
          errorMessage='Must match new Password'
        ></Input>
      </div>
      <div className='flex flex-row gap-3'>
        <Button
          id='passwordChangeCancelButton'
          buttonType='outlined'
          buttonText='Cancel'
          onClickFunction={() => {
            setState('INITIAL');
          }}
        />
        <Button
          id='passwordChangeSaveButton'
          buttonType='filled'
          buttonText='Save'
          disableCondition={!saveAvailable()}
          onClickFunction={() => {
            changePassword();
          }}
        />
      </div>
    </div>
  );
};

export default Change;
