import React from 'react';
const UserIcon = ({ className }) => {
  return (
    <div className={className}>
      <svg
        viewBox='0 0 48 48'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M12.0801 13.8867C12.0801 17.0392 13.3359 20.0626 15.5714 22.2918C17.8069 24.521 20.8388 25.7734 24.0002 25.7734C27.1616 25.7734 30.1936 24.521 32.429 22.2918C34.6645 20.0626 35.9204 17.0392 35.9204 13.8867C35.9204 10.7341 34.6645 7.71071 32.429 5.48153C30.1936 3.25234 27.1616 2 24.0002 2C20.8388 2 17.8069 3.25234 15.5714 5.48153C13.3359 7.71071 12.0801 10.7341 12.0801 13.8867V13.8867Z'
          strokeWidth='2.6'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M2.95508 46.095C4.02609 42.8221 5.86017 39.813 8.34702 37.3331C12.4986 33.1932 18.1293 30.8674 24.0005 30.8674C29.8717 30.8674 35.5025 33.1932 39.654 37.3331C42.1409 39.813 43.975 42.8221 45.046 46.095'
          strokeWidth='2.6'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </div>
  );
};
export default UserIcon;
