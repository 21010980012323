import React, { useState } from 'react';
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const SwitchToggle = ({ buttonText, onChange, checked, id, disabled }) => {
  const [focused, setFocused] = useState(false);
  const [pressed, setPreseed] = useState(false);
  const [hovered, setHovered] = useState(false);
  if (checked) {
    return (
      <label
        data-testid={id}
        htmlFor={id}
        className={classNames(
          'inline-flex items-center',
          disabled ? 'cursor-not-allowed' : 'cursor-pointer'
        )}
        onMouseDown={() => {
          setPreseed(true);
        }}
        onMouseUp={() => {
          setPreseed(false);
        }}
        onMouseLeave={() => {
          setPreseed(false);
          setHovered(false);
        }}
        onMouseEnter={() => {
          setHovered(true);
        }}
      >
        <span className='relative'>
          <span
            style={{ outline: '1px solid white' }}
            className={classNames(
              'block w-12 h-6 rounded-full shadow-inner',
              focused ? 'drop-shadow-blue-bay' : '',
              disabled
                ? 'bg-spanish-gray'
                : pressed
                ? 'bg-[#0F5180]'
                : hovered
                ? 'bg-electric-blue'
                : 'bg-[#0E6EB9]'
            )}
          ></span>
          <span
            className={classNames(
              'absolute block w-4 h-4 mt-1 ml-1 rounded-full shadow inset-y-0 left-2 focus-within:shadow-outline transition-transform duration-300 ease-in-out transform translate-x-full',
              disabled ? 'bg-platinum' : 'bg-white'
            )}
          >
            <input
              id={id}
              type='checkbox'
              className='absolute opacity-0 w-0 h-0'
              checked={checked}
              onChange={() => {
                if (!disabled) {
                  onChange(!checked);
                }
              }}
              onFocus={() => {
                setFocused(true);
              }}
              onBlur={() => {
                setFocused(false);
              }}
            />
          </span>
        </span>
        {buttonText !== undefined && buttonText !== '' ? (
          <span className='ml-3 text-sm'>{buttonText}</span>
        ) : (
          <></>
        )}
      </label>
    );
  } else {
    return (
      <label
        data-testid={id}
        htmlFor={id}
        className={classNames(
          'inline-flex items-center',
          disabled ? 'cursor-not-allowed' : 'cursor-pointer'
        )}
        onMouseDown={() => {
          setPreseed(true);
        }}
        onMouseUp={() => {
          setPreseed(false);
        }}
        onMouseLeave={() => {
          setPreseed(false);
          setHovered(false);
        }}
        onMouseEnter={() => {
          setHovered(true);
        }}
      >
        <span className='relative'>
          <span
            style={{ outline: '1px solid white' }}
            className={classNames(
              'block w-12 h-6 rounded-full shadow-inner',
              focused ? 'drop-shadow-blue-bay' : '',
              disabled
                ? 'bg-platinum'
                : pressed
                ? 'bg-white border-[#0F5180] border-2'
                : hovered
                ? 'border-2 bg-white border-[#0E6EB9]'
                : 'bg-white border-[#0E6EB9] border '
            )}
          ></span>
          <span
            className={classNames(
              'absolute block w-4 h-4 mt-1 ml-1 rounded-full shadow inset-y-0 left-0 focus-within:shadow-outline transition-transform duration-300 ease-in-out',
              disabled
                ? 'bg-spanish-gray'
                : pressed
                ? 'border-[#0F5180] bg-[#0F5180]'
                : hovered
                ? 'bg-[#0E6EB9]'
                : 'border-[#0E6EB9] bg-white border '
            )}
          >
            <input
              id={id}
              type='checkbox'
              className='absolute opacity-0 w-0 h-0'
              checked={checked}
              onChange={() => {
                if (!disabled) {
                  onChange(!checked);
                }
              }}
              onFocus={() => {
                setFocused(true);
              }}
              onBlur={() => {
                setFocused(false);
              }}
            />
          </span>
        </span>
        {buttonText !== undefined && buttonText !== '' ? (
          <span className='ml-3 text-sm'>{buttonText}</span>
        ) : (
          <></>
        )}
      </label>
    );
  }
};

export default SwitchToggle;
