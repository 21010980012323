import React, { useState } from 'react';
import CustomLink from '../CustomLink';

const LinkGroup = ({ name, links }) => {
  const [groupCollapsed, setGroupCollapsed] = useState(true);
  function changeClass() {
    if (groupCollapsed) {
      setGroupCollapsed(false);
    } else {
      setGroupCollapsed(true);
    }
  }

  let customLinks = [];
  for (const key in links) {
    customLinks.push(
      <CustomLink
        text={key}
        key={key}
        link={links[key]}
        type='Footer'
      />
    );
  }
  return (
    <div className='flex flex-col px-3 sm:w-1/3 xl:w-1/5 xl:mx-auto py-2 border-t last:border-b sm:border-none border-white cursor-pointer sm:cursor-default'>
      <div
        className='text-white py-1 text-lg font-extrabold flex flex-row justify-between font-neusa'
        onClick={changeClass}
      >
        <span>{name}</span>
        <span className='flex sm:hidden'>{`${groupCollapsed ? '+' : '-'}`}</span>
      </div>
      <div className={`flex-col sm:flex ${groupCollapsed ? 'hidden' : 'flex'}`}>
        {customLinks}
      </div>
    </div>
  );
};
export default LinkGroup;
