import React, { useContext, useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import CustomLink from './CustomLink';
import ProfileImage from './ProfileImage';
import { UserContext, IsUserFederatedContext } from '../MainContent';
import { SettingsContext } from '../../App';
import Dropdown from './Dropdown';
import { Menu } from '@headlessui/react';
import {
  UserIcon,
  ShieldCheckIcon,
  LockClosedIcon,
  LogoutIcon,
} from '@heroicons/react/outline';
import { Link, useLocation } from 'react-router-dom';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Header = () => {
  const userInfo = useContext(UserContext);
  const isUserFederated = useContext(IsUserFederatedContext);
  const settings = useContext(SettingsContext);
  const { oktaAuth } = useOktaAuth() || {};
  const logout = () => oktaAuth.signOut();
  const linksJson = JSON.parse(settings.HeaderLinks);
  const [user, setUser] = useState('User');
  const [menuState, setMenuState] = useState('');
  const location = useLocation();
  let links = [];

  useEffect(() => {
    if (userInfo !== undefined && userInfo !== null) {
      setUser(userInfo.name);
    }
  }, [userInfo]);

  useEffect(() => {
    let menu = location.pathname.substring(1);
    if (menu === 'login/callback') {
      menu = 'profile';
    }
    setMenuState(menu);
  }, [location.pathname]);

  let dropdownOptions = [];
  dropdownOptions.push(
    <Menu.Item key='profile'>
      {({ active }) => (
        <Link to='/profile'>
          <div
            data-testid='headerProfile'
            className={classNames(
              'hover:bg-white-smoke flex flex-row items-center gap-2 h-8',
              active ? 'bg-white-smoke' : ''
            )}
          >
            <UserIcon
              className={classNames(
                'ml-5 w-4 h-4',
                menuState === 'profile' ? 'text-blue-bay' : 'text-eerie-black'
              )}
            />
            <span className='text-eerie-black text-sm'>My Profile</span>
          </div>
        </Link>
      )}
    </Menu.Item>
  );
  if (!isUserFederated) {
    dropdownOptions.push(
      <Menu.Item key='password'>
        {({ active }) => (
          <Link to='/password' className='w-[150px]'>
            <div
              data-testid='headerPassword'
              className={classNames(
                'hover:bg-white-smoke flex flex-row items-center gap-2 h-8',
                active ? 'bg-white-smoke' : ''
              )}
            >
              <LockClosedIcon
                className={classNames(
                  'ml-5 w-4 h-4',
                  menuState === 'password'
                    ? 'text-blue-bay'
                    : 'text-eerie-black'
                )}
              />
              <span className='text-eerie-black text-sm'>Password</span>
            </div>
          </Link>
        )}
      </Menu.Item>
    );
    dropdownOptions.push(
      <Menu.Item key='security'>
        {({ active }) => (
          <Link to='/security' className='w-[150px]'>
            <div
              data-testid='headerSecurity'
              className={classNames(
                'hover:bg-white-smoke flex flex-row items-center gap-2 h-8',
                active ? 'bg-white-smoke' : ''
              )}
            >
              <ShieldCheckIcon
                className={classNames(
                  'ml-5 w-4 h-4',
                  menuState === 'security'
                    ? 'text-blue-bay'
                    : 'text-eerie-black'
                )}
              />
              <span className='text-eerie-black text-sm'>Security</span>
            </div>
          </Link>
        )}
      </Menu.Item>
    );
  }
  dropdownOptions.push(
    <Menu.Item key='logout' className='w-[150px]'>
      {({ active }) => (
        <Link
          to='/#'
          onClick={() => {
            localStorage.clear();
            logout();
          }}
        >
          <div
            data-testid='headerLogout'
            className={classNames(
              'hover:bg-white-smoke flex flex-row items-center gap-2 h-8 rounded-b',
              active ? 'bg-white-smoke' : ''
            )}
          >
            <LogoutIcon className='ml-5 text-eerie-black w-4 h-4' />
            <span className='text-eerie-black text-sm'>Logout</span>
          </div>
        </Link>
      )}
    </Menu.Item>
  );

  for (const key in linksJson) {
    links.push(
      <CustomLink text={key} key={key} link={linksJson[key]} type='Header' />
    );
  }

  const skipToContent = () => {
    window.location.hash = 'main';
  };

  return (
    <div
      data-testid='container'
      className='main-content-shadow first:flex flex-col'
    >
      <div className='bg-white-smoke'>
        <div className='flex flex-row  items-center gap-10 justify-end h-10 mr-[120px]'>
          <button
            className='focus:underline focus:translate-y-0 left-[10%] translate-y-[-150%]'
            onClick={skipToContent}
          >
            Skip to content
          </button>
          {links}
          <Dropdown
            dropdownTitle={
              <div
                data-testid='headerUser'
                className='flex flex-row items-center justify-center gap-1'
              >
                <ProfileImage
                  size='small'
                  alt='User'
                  src=''
                  upload='false'
                  border={true}
                  borderColor='border-black'
                  iconColor='stroke-carbon'
                  color='bg-white-smoke'
                />
                <span className='text-base font-semibold text-eerie-black hover:underline'>
                  {user}
                  <span
                    className='max-w-0 max-h-0 p-0 m-0 overflow-hidden whitespace-nowrap absolute'
                  >
                    Dropdown menu. Move inside with arrow keys. Exit with ESC
                  </span>
                </span>
              </div>
            }
            dropdownOptions={dropdownOptions}
            maxDropdownHeight={true}
          />
        </div>

        <div className='bg-white h-[110px] flex flex-row items-center'>
          <img
            className='h-12 w-12 ml-[108px]'
            src='https://cdn.f5.com/webcommon/logo/logo.svg'
            alt='F5, Inc. logo'
          />
          <span className='ml-4 text-3xl leading-8 font-bold font-neusa'>
            My Profile
          </span>
        </div>
      </div>
    </div>
  );
};
export default Header;
