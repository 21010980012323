import React, { useEffect, useState } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { LoginCallback, Security, SecureRoute } from '@okta/okta-react';
import { createOktaAuth } from './helpers/oktaClient';
import MainContent from './components/MainContent';
import LoadingDialog from './components/shared/LoadingDialog';
import EmptyRoute from './components/shared/EmptyRoute';
import { getSettings } from './helpers/mfa';

export const SettingsContext = React.createContext({});
const App = () => {
  const [oktaAuth, setOktaAuth] = useState(null);
  const [settings, setSettings] = useState(null);
  const [error, setError] = useState(false);
  useEffect(() => {
    async function createAuth() {
      const localSettings = await getSettings();
      if (localSettings !== null) {
        const auth = createOktaAuth(
          localSettings.Domain,
          localSettings.ClientId
        );
        setOktaAuth(auth);
        setSettings(localSettings);
      } else {
        setError(true);
      }
    }
    createAuth();
  }, []);

  const history = useHistory();
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  return oktaAuth !== null ? (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <SettingsContext.Provider value={settings}>
        <SecureRoute path='/' exact={true} component={EmptyRoute} />
        <MainContent />
        <Route path='/login/callback' component={LoginCallback} />
      </SettingsContext.Provider>
    </Security>
  ) : !error ? (
    <div className='h-content'>
      <LoadingDialog open={true} headerText='Loading' />
    </div>
  ) : (
    <div>Not able to load settings</div>
  );
};

export default App;
