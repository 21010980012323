import React, { useState, useEffect, useContext } from 'react';
import ProfileImage from './ProfileImage';
import { Link, useLocation } from 'react-router-dom';
import { IsUserFederatedContext } from '../MainContent';
import {
  ShieldCheckIcon,
  LockClosedIcon,
} from '@heroicons/react/outline';
import UserIcon from './UserIcon'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const SideNav = () => {
  const location = useLocation();
  const [menuState, setMenuState] = useState('');
  const [hoverState, setHoverState] = useState('');
  const isUserFederated = useContext(IsUserFederatedContext);

  useEffect(() => {
    let menu = location.pathname.substring(1);
    if (menu === 'login/callback') {
      menu = 'profile';
    }
    setMenuState(menu);
  }, [location.pathname]);

  return (
    <div
      className='flex flex-col flex-initial w-[184px] h-[778px] rounded
      main-content-shadow bg-white border border-platinum'
    >
      <div className='flex flex-col items-center justify-center my-[30px]'>
        <ProfileImage
          size='big'
          alt='User'
          src=''
          upload='false'
          iconColor='stroke-white'
        />
      </div>
      <Link
        to='/profile'
        className='flex h-14 items-center border-b-2 border-platinum'
        onClick={() => {
          setMenuState('profile');
        }}
      >
        <div
          data-testid='sideNavProfile'
          className='h-8 flex flex-row items-center w-full hover:bg-white-smoke'
          onMouseEnter={() => {
            setHoverState('profile');
          }}
          onMouseLeave={() => {
            setHoverState('');
          }}
        >
          <div
            className={classNames(
              menuState === 'profile'
                ? 'border-blue-bay bg-blue-bay  border rounded-r-sm'
                : 'border-none',
              'h-8 w-[2px] '
            )}
          />
          <div className='w-9  flex items-center justify-center'>
            <UserIcon
              className={classNames(
                'w-4 h-4',
                menuState === 'profile'
                  ? 'stroke-blue-bay'
                  : hoverState === 'profile'
                  ? 'stroke-carbon'
                  : 'stroke-spanish-gray'
              )}
            />
          </div>
          <span className='text-lg text-eerie-black font-semibold'>
            My Profile
          </span>
        </div>
      </Link>
      {isUserFederated ? (
        <></>
      ) : (
        <Link
          to='/password'
          className='flex h-14 items-center border-b-2 border-platinum'
          onClick={() => {
            setMenuState('password');
          }}
        >
          <div
            data-testid='sideNavPassword'
            className='h-8 flex flex-row items-center w-full hover:bg-white-smoke'
            onMouseEnter={() => {
              setHoverState('password');
            }}
            onMouseLeave={() => {
              setHoverState('');
            }}
          >
            <div
              className={classNames(
                menuState === 'password'
                  ? 'border-blue-bay bg-blue-bay  border rounded-r-sm'
                  : 'border-none',
                'h-8 w-[2px] '
              )}
            />
            <div className='w-9 flex items-center justify-center '>
              <LockClosedIcon
                className={classNames(
                  'w-4 h-4',
                  menuState === 'password'
                    ? 'text-blue-bay'
                    : hoverState === 'password'
                    ? 'text-carbon'
                    : 'text-spanish-gray'
                )}
              />
            </div>
            <span className='text-lg text-eerie-black font-semibold'>
              Password
            </span>
          </div>
        </Link>
      )}
      {isUserFederated ? (
        <></>
      ) : (
        <Link
          to='/security'
          className='flex h-14 items-center border-b-2 border-platinum'
          onClick={() => {
            setMenuState('security');
          }}
        >
          <div
            data-testid='sideNavSecurity'
            className='h-8 flex flex-row items-center w-full hover:bg-white-smoke'
            onMouseEnter={() => {
              setHoverState('security');
            }}
            onMouseLeave={() => {
              setHoverState('');
            }}
          >
            <div
              className={classNames(
                menuState === 'security'
                  ? 'border-blue-bay bg-blue-bay  border rounded-r-sm'
                  : 'border-none',
                'h-8 w-[2px] '
              )}
            />
            <div className='w-9 flex items-center justify-center'>
              <ShieldCheckIcon
                className={classNames(
                  'w-4 h-4',
                  menuState === 'security'
                    ? 'text-blue-bay'
                    : hoverState === 'security'
                    ? 'text-carbon'
                    : 'text-spanish-gray'
                )}
              />
            </div>
            <span className='text-lg text-eerie-black font-semibold'>
              Security
            </span>
          </div>
        </Link>
      )}
    </div>
  );
};
export default SideNav;
