import React, { useEffect, useState } from 'react';
import ResetFactor from './ResetFactor';

const ActiveFactor = ({
  setEnrollState,
  setGaStatus,
  factorsData,
  setFactorsData,
  factorsCount,
  setFactorsCount,
}) => {
  const [date, setDate] = useState('');
  const options = { year: 'numeric', month: 'short', day: 'numeric' };

  useEffect(() => {
    const tmpDate = new Date(factorsData.googleAuth.enrolledDate);
    setDate(tmpDate.toLocaleDateString('en-US', options));
  }, []);

  return (
    <div data-testid='googleAuthActive' className='flex flex-col mt-1 gap-2'>
      <span
        data-testid='googleAuthEnrolledDateSpan'
        className='text-carbon text-sm'
      >
        Added {date}
      </span>
      <ResetFactor
        setEnrollState={setEnrollState}
        setGaStatus={setGaStatus}
        factorsData={factorsData}
        setFactorsData={setFactorsData}
        factorsCount={factorsCount}
        setFactorsCount={setFactorsCount}
      />
    </div>
  );
};

export default ActiveFactor;
