import React, { useEffect, useContext, useState } from 'react';
import FactorState from './FactorState';
import { optIntoEmail, optOutOfEmail } from '../../../helpers/mfaEmail';
import { UserContext, AccessTokenContext } from '../../MainContent';
import Switch from '../../shared/Switch';
import ConfirmDialog from '../../shared/ConfirmDialog';
import FactorError from '../FactorError';
import LoadingSpinner from '../../shared/LoadingSpinner';
import { Store } from 'react-notifications-component';
import NotificationBody from '../../shared/NotificationBody';

const FactorContainer = ({
  emailStatus,
  setEmailStatus,
  isEnrolling,
  setIsEnrolling,
  factorsData,
  setFactorsData,
  factorsCount,
  setFactorsCount,
  loading,
  setLoading
}) => {
  const accessToken = useContext(AccessTokenContext);
  const userInfo = useContext(UserContext);
  const [openDialog, setOpenDialog] = useState(false);
  const [turnOff, setTurnOff] = useState(false);
  const [error, setError] = useState(false);
  const [loadingSpinner, setLoadingSpinner] = useState(false);

  useEffect(() => {
    async function optOut() {
      if (turnOff) {
        setLoading(true);
        setLoadingSpinner(true);
        let response = await optOutOfEmail(userInfo, accessToken);
        if (response) {
          setError(false);
          setEmailStatus(false);
          setOpenDialog(false);
          Store.addNotification({
            content: (
              <NotificationBody
                id='emailOptOutNotification'
                text='Your changes were saved.'
              />
            ),
            container: 'top-right',
            dismiss: {
              duration: 30000,
              click: false,
            },
            animationIn: ['animate__animated', 'animate__fadeInRight'],
            animationOut: ['animate__animated', 'animate__fadeOutRight'],
            id: 'emailOptOutNotification',
          });
          let newData = factorsData;
          delete newData['email'];
          setFactorsData(newData);
          const prevFactorsCount = factorsCount;
          setFactorsCount(prevFactorsCount - 1);
        } else {
          setError(true);
        }
        setLoading(false);
        setLoadingSpinner(false);
      }
    }
    optOut();
  }, [turnOff]);

  useEffect(() => {
    if (!emailStatus) {
      if (isEnrolling.includes('email')) {
        let tmp = [].concat(isEnrolling);
        tmp.pop();
        setIsEnrolling(tmp);
      }
    }
  }, [emailStatus]);

  async function handleInputChange(newValue) {
    if (newValue) {
      setLoading(true);
      setLoadingSpinner(true);
      let response = await optIntoEmail(userInfo, accessToken);
      if (response) {
        setEmailStatus(newValue);
        const prevFactorsCount = factorsCount;
        setFactorsCount(prevFactorsCount + 1);
      } else {
        setError(true);
      }
      setLoading(false);
      setLoadingSpinner(false);
    } else {
      if ('email' in factorsData) {
        setOpenDialog(true);
      } else {
        setTurnOff(true);
      }
    }
  }

  const switchHiddenText = (
    <span
      className='max-w-0 max-h-0 p-0 m-0 overflow-hidden whitespace-nowrap absolute'
    >
      Switch to toggle your Email second factor on and off
    </span>
  );

  return (
    <div data-testid='emailContainer' className='border-b border-platinum'>
      <ConfirmDialog
        id ='emailTurnOffDialog'
        open={openDialog}
        setOpen={setOpenDialog}
        setResponse={setTurnOff}
        headerText='Turn off verification with email?'
        bodyText="You won't have the option to use verification codes via email when you sign in to your F5 account. This Authenticator app option will not be removed from your account and can be turned back on at any time."
      ></ConfirmDialog>
      <div className='my-9 mx-16'>
        <div className='flex flex-row justify-between'>
          <div className='flex flex-col'>
            <span className='text-base font-bold text-carbon'>
              {'email' in factorsData && emailStatus ? 'Your ' : ''}Email
              Factor
            </span>
            {'email' in factorsData && emailStatus ? (
              <></>
            ) : (
              <span className='text-base text-carbon'>
                Use verification codes via email.
              </span>
            )}
          </div>
          <div className='h-min w-min flex flex-row-reverse gap-1'>
            <Switch
              checked={emailStatus}
              onChange={handleInputChange}
              disabled={(loading || factorsCount < 2) && emailStatus}
              id={'emailSwitch'}
              buttonText={switchHiddenText}
            />
            {loadingSpinner ? (
              <LoadingSpinner id='emailSwitchLoading' height='h-6' width='w-6' margin={'mb-1'} />
            ) : (
              <></>
            )}
          </div>
        </div>
        {emailStatus &&
        (isEnrolling.length === 0 || isEnrolling[0] === 'email') && !error ? (
          <FactorState
            isEnrolling={isEnrolling}
            setIsEnrolling={setIsEnrolling}
            setEmailStatus={setEmailStatus}
            factorsData={factorsData}
            setFactorsData={setFactorsData}
            factorsCount={factorsCount}
            setFactorsCount={setFactorsCount}
          />
        ) : error ? (
          <FactorError />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default FactorContainer;
