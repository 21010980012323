import React from 'react';
import EnrollStepOne from './EnrollStepOne';
import EnrollStepTwo from './EnrollStepTwo';

const Enrollment = ({
  enrollState,
  setEnrollState,
  factorsData,
  setFactorsData,
  factorsCount,
  setFactorsCount
}) => {
  return (
    <div className='flex flex-col my-7 gap-7'>
      <EnrollStepOne
        setEnrollState={setEnrollState}
      />
      {enrollState === 'PENDING_ACTIVATION' ? (
        <EnrollStepTwo
          setEnrollState={setEnrollState}
          factorsData={factorsData}
          setFactorsData={setFactorsData}
          factorsCount={factorsCount}
          setFactorsCount={setFactorsCount}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default Enrollment;
