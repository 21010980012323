import React, { useState, useContext } from 'react';
import { Store } from 'react-notifications-component';
import { UserContext, AccessTokenContext } from '../../MainContent';
import { emailActivate, maskEmail } from '../../../helpers/mfaEmail';
import NotificationBody from '../../shared/NotificationBody';
import LoadingDialog from '../../shared/LoadingDialog';
import Input from '../../shared/Input';
import Button from '../../shared/Button';

const EnrollStepTwo = ({
  setEnrollState,
  factorsData,
  setFactorsData,
  factorsCount,
  setFactorsCount
}) => {
  const [verificationCode, setVerificationCode] = useState('');
  const [isInputEnabled, setIsInputEnabled] = useState(true);
  const [isCodeInvalid, setIsCodeInvalid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const userInfo = useContext(UserContext);
  const accessToken = useContext(AccessTokenContext);

  const handleInputChange = (event) => {
    setVerificationCode(event.target.value.trim());
  };

  const activateFactor = async () => {
    try {
      setIsLoading(true);
      setIsInputEnabled(false);
      const activateResponse = await emailActivate(
        userInfo,
        accessToken,
        verificationCode
      )
      if (activateResponse) {
        setEnrollState('ACTIVE');
        let newData = factorsData;
        newData.email = {
          maskedEmail: maskEmail(userInfo.email),
          status: 'ACTIVE',
          enrolledDate: new Date(),
        };
        setFactorsData(newData);
        const prevFactorsCount = factorsCount;
        setFactorsCount(prevFactorsCount + 1);
        Store.addNotification({
          content: (
            <NotificationBody
              id='emailEnrollNotification'
              text='Your changes are saved.'
            />
          ),
          container: 'top-right',
          dismiss: {
            duration: 30000,
            click: false,
          },
          animationIn: ['animate__animated', 'animate__fadeInRight'],
          animationOut: ['animate__animated', 'animate__fadeOutRight'],
          id: 'emailEnrollNotification',
        });
      } else {
        setIsCodeInvalid(true);
      }
      setIsLoading(false);
      setIsInputEnabled(true);
    } catch (error) {
      setEnrollState('ERROR')
    }
  };

  return (
    <div
      data-testid='emailEnrollmentVerification'
      className='flex flex-row gap-6'
    >
      <LoadingDialog
        id='emailVerificationLoadingDialog'
        open={isLoading}
        bodyText='Please wait a moment while we verify the code'
        headerText='Verifying'
      />
      <span className='text-base text-carbon font-bold'>Step 2</span>
      <div className='flex flex-col gap-3'>
        <span className='text-base text-carbon font-bold'>
          We sent a verification code to your email.
        </span>
        <span className='-mb-3'>Verification Code</span>
        <div className='flex flex-row'>
          <div className='w-48 mr-3'>
            <Input
              id='emailVerificationInput'
              inputType='slim'
              inputValue={verificationCode}
              inputPlaceholder=''
              onChangeFunction={handleInputChange}
              disabledCondition={!isInputEnabled}
              error={isCodeInvalid}
              errorMessage='Invalid verification code'
            />
          </div>
          <Button
            id='emailVerificationButton'
            buttonType='outlined'
            buttonText={'Verify'}
            disableCondition={verificationCode.length < 1 || isLoading}
            onClickFunction={() => activateFactor()}
          />
        </div>
      </div>
    </div>
  );
};

export default EnrollStepTwo;