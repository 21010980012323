import React from 'react';
import Button from '../../shared/Button';
import { FocusTrap } from '@headlessui/react';

const SetupKeyPopup = ({ open, setOpen, qrSecret }) => {
  if (open) {
    return (
      <div
        data-testid='qrSecretDialog'
        className='fixed top-0 left-0 bg-[#E5E5E5] bg-opacity-50 w-full h-full
          outline-none overflow-hidden flex justify-center items-center z-50'
      >
        <FocusTrap>
          <div className='w-[440px]'>
            <div
              className='border-none shadow-dialog relative flex flex-col w-full 
              bg-white rounded outline-none'
            >
              <div
                className='flex flex-shrink-0 items-center 
                justify-between px-8 py-4 border-b border-platinum rounded-t'
              >
                <span className='text-lg text-carbon'>Enter a Setup Key</span>
              </div>
              <div className='relative px-8 pt-6 text-base text-carbon'>
                <ol className='list-decimal'>
                  <li>
                    In the Google Authenticator alip, tap the '+' icon and then
                    tap on <b>Enter a setup key</b>.
                  </li>
                  <li>
                    Enter your email address and this key (spaces don't matter):&nbsp;
                    <b data-testid='qrSecretDialog-qrSecretText'>{qrSecret}</b>
                  </li>
                  <li>
                    Make sure <b>Time based</b> is selected.
                  </li>
                  <li>
                    Tap <b>Add</b> to finish.
                  </li>
                </ol>
              </div>
              <div className='flex flex-row gap-3 items-center justify-end py-6 px-5'>
                <Button
                  id='qrSecretDialog-closeButton'
                  buttonType='filled'
                  buttonText='Close'
                  onClickFunction={() => {
                    setOpen(false);
                  }}
                ></Button>
              </div>
            </div>
          </div>
        </FocusTrap>
      </div>
    );
  }
};

export default SetupKeyPopup;
